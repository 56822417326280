import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/packages",
    name: "packages",
    component: () => import("@/views/packages/PackageIndex.vue"),
    meta: {
      pageTitle: "Paketler",
      module: RoleModuleEnum.DIET_DETOX,
    },
  },
  {
    path: "/packages/create",
    name: "package-add",
    component: () => import("@/views/packages/PackageEdit.vue"),
    meta: {
      pageTitle: "Yeni Paket",
    },
  },
  {
    path: "/packages/edit/:id",
    name: "package-edit",
    component: () => import("@/views/packages/PackageEdit.vue"),
    meta: {
      pageTitle: "Paket",
    },
  },
];
