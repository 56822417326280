import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/finance",
    name: "finance",
    component: () => import("@/views/finance/FinanceIndex.vue"),
    meta: {
      pageTitle: "Finans",
      module: RoleModuleEnum.CUSTOMER,
    },
  },
  {
    path: "/finance/:id",
    name: "finance-detail",
    component: () => import("@/views/finance/FinanceDetail.vue"),
    redirect: "payments",
    meta: {
      pageTitle: "Paket Detayı",
    },
    children: [
      {
        path: "payments",
        name: "finance-detail-payments",
        component: () => import("@/views/finance/tabs/FinancePayments.vue"),
        meta: {
          pageTitle: "Ödemeler",
        },
      },
      {
        path: "transfers",
        name: "finance-detail-transfers",
        component: () => import("@/views/finance/tabs/FinanceTransfers.vue"),
        meta: {
          pageTitle: "Havaleler",
        },
      },
    ],
  },
];
