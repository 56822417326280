<template>
  <!-- TODO design will be changed -->
  <DatePicker
    placeholder="Tarih"
    :enable-time-picker="false"
    utc
    v-model="value"
    locale="tr"
    cancel-text="İptal"
    select-text="Onayla"
  ></DatePicker>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ResourceFormDatepicker",
  props: {
    modelValue: {
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const value = ref();

    onMounted(() => {
      value.value = props.modelValue;
    });

    watch(value, (val) => {
      console.log(val);
      emit("update:modelValue", val);
    });

    return {
      value,
    };
  },
});
</script>
