import { FoodUnitEnum } from "@/store/enums/FoodUnitEnum";

export default class FoodModel {
  id: number | null;
  name: string | null;
  unit: FoodUnitEnum | null;
  calorie: string | null;
  photo: string | null;
  quantity?: string;

  constructor(
    id: number | null,
    name: string | null,
    unit: FoodUnitEnum | null,
    calorie: string | null,
    photo: string | null,
    quantity?: string
  ) {
    this.id = id;
    this.name = name;
    this.unit = unit;
    this.calorie = calorie;
    this.photo = photo;
    this.quantity = quantity;
  }
}
