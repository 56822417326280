export enum Endpoints {
  /* Authentication */
  AUTH_M2M_TOKEN = "oauth/token",
  AUTH_USER_LOGIN = "auth/login",
  AUTH_TWO_FA_VERIFY = "auth/2fa-verify",
  AUTH_USER_LOGOUT = "auth/logout",
  AUTH_USER_SELF = "user/self",

  /* Notification */
  NOTIFICATION_CUSTOMERS = "app/customers",
  NOTIFICATION_SEND_APP_NOTIFICATION = "app/send-push-notification",

  /* Settings */
  SETTINGS_ACTIVITY_INDEX = "settings/activities",
  SETTINGS_ACTIVITY_STORE = "settings/activities",
  SETTINGS_CLINIC_INDEX = "settings/clinics",
  SETTINGS_CLINIC_STORE = "settings/clinics",
  SETTINGS_JOB_INDEX = "settings/jobs",
  SETTINGS_JOB_STORE = "settings/jobs",
  SETTINGS_DISEASE_INDEX = "settings/diseases",
  SETTINGS_DISEASE_STORE = "settings/diseases",
  SETTINGS_ALLERGY_INDEX = "settings/allergies",
  SETTINGS_ALLERGY_STORE = "settings/allergies",
  SETTINGS_EATING_HABIT_INDEX = "settings/eating-habits",
  SETTINGS_EATING_HABIT_STORE = "settings/eating-habits",
  SETTINGS_PHYSICAL_ACTIVITY_INDEX = "settings/physical-activities",
  SETTINGS_PHYSICAL_ACTIVITY_STORE = "settings/physical-activities",
  SETTINGS_DAILY_CAFFEINE_INDEX = "settings/daily-caffeine",
  SETTINGS_DAILY_CAFFEINE_STORE = "settings/daily-caffeine",
  SETTINGS_DAILY_WATER_INDEX = "settings/daily-waters",
  SETTINGS_DAILY_WATER_STORE = "settings/daily-waters",
  SETTINGS_SLEEP_PATTERN_INDEX = "settings/sleep-patterns",
  SETTINGS_SLEEP_PATTERN_STORE = "settings/sleep-patterns",
  SETTINGS_MEAL_TIME_INDEX = "settings/meal-times",
  SETTINGS_MEAL_TIME_STORE = "settings/meal-times",
  SETTINGS_HEALTH_PROBLEM_INDEX = "settings/health-problems",
  SETTINGS_HEALTH_PROBLEM_STORE = "settings/health-problems",
  SETTINGS_EXTRA_CASE_INDEX = "settings/extra-cases",
  SETTINGS_EXTRA_CASE_STORE = "settings/extra-cases",
  SETTINGS_DIET_COMPATIBILITY_INDEX = "settings/diet-compatibilities",
  SETTINGS_DIET_COMPATIBILITY_STORE = "settings/diet-compatibilities",
  SETTINGS_WEEKLY_EXERCISE_COUNT_INDEX = "settings/weekly-exercise-counts",
  SETTINGS_WEEKLY_EXERCISE_COUNT_STORE = "settings/weekly-exercise-counts",
  SETTINGS_COUNTRY_INDEX = "settings/countries",
  SETTINGS_CITY_INDEX = "settings/cities/{id}",

  /* Team */
  TEAM_MEMBER_INDEX = "team/members",
  TEAM_MEMBER_SHOW = "team/members/{id}",
  TEAM_MEMBER_STORE = "team/members",
  TEAM_MEMBER_UPDATE = "team/members/{id}",
  TEAM_MEMBER_DESTROY = "team/members/{id}",
  TEAM_MEMBER_LOG_INDEX = "team/members/{id}/logs",
  TEAM_MEMBER_CHANGE_ROLE = "team/members/{id}/change-role",
  TEAM_MEMBER_STORE_IMAGE = "team/members/{id}/upload-avatar",
  TEAM_ROLE_INDEX = "team/roles",
  TEAM_ROLE_SHOW = "team/roles/{id}",
  TEAM_ROLE_STORE = "team/roles",
  TEAM_ROLE_UPDATE = "team/roles/{id}",
  TEAM_ROLE_DESTROY = "team/roles/{id}",

  /* Call Center */
  CALL_CENTER_CUSTOMER_INDEX = "call-center/customers",
  CALL_CENTER_CUSTOMER_SHOW = "call-center/customers/{id}",
  CALL_CENTER_CUSTOMER_STORE = "call-center/customers/{id}",
  CALL_CENTER_CALL_REQUEST_INDEX = "call-center/call-requests",
  CALL_CENTER_CALL_REQUEST_CLOSE = "call-center/call-requests/{id}/close",

  /* Package */
  PACKAGE_INDEX = "packages",
  PACKAGE_SHOW = "packages/{id}",
  PACKAGE_STORE = "packages",
  PACKAGE_UPDATE = "packages/{id}",
  PACKAGE_DESTROY = "packages/{id}",

  /* Messages */
  MESSAGE_QUICK_MESSAGE_INDEX = "message/quick-messages",
  MESSAGE_QUICK_MESSAGE_SHOW = "message/quick-messages/{id}",
  MESSAGE_QUICK_MESSAGE_STORE = "message/quick-messages",
  MESSAGE_QUICK_MESSAGE_UPDATE = "message/quick-messages/{id}",
  MESSAGE_QUICK_MESSAGE_DESTROY = "message/quick-messages/{id}",
  MESSAGE_QUICK_ANSWER_INDEX = "message/quick-answers",
  MESSAGE_QUICK_ANSWER_SHOW = "message/quick-answers/{id}",
  MESSAGE_QUICK_ANSWER_STORE = "message/quick-answers",
  MESSAGE_QUICK_ANSWER_UPDATE = "message/quick-answers/{id}",
  MESSAGE_QUICK_ANSWER_DESTROY = "message/quick-answers/{id}",
  MESSAGE_CUSTOMERS = "message/customers",

  /* Finance */
  FINANCE_CUSTOMER_PACKAGE_INDEX = "finance/customer-packages",
  FINANCE_CUSTOMER_PACKAGE_STORE = "finance/customer-packages",
  FINANCE_CUSTOMER_PACKAGE_TOGGLE_PACKAGE_STATUS = "finance/customer-packages/{id}/toggle-stop-status",
  FINANCE_CUSTOMER_PACKAGE_PAYMENT_INDEX = "finance/customer-packages/{id}/payments",
  FINANCE_CUSTOMER_PACKAGE_PAYMENT_STORE = "finance/customer-packages/{id}/add-payment",
  FINANCE_SALES = "finance/customer-packages/{type}",
  FINANCE_BANK_ACCOUNT_INDEX = "finance/bank-accounts",
  FINANCE_BANK_ACCOUNT_SHOW = "finance/bank-accounts/{id}",
  FINANCE_BANK_ACCOUNT_STORE = "finance/bank-accounts",
  FINANCE_BANK_ACCOUNT_UPDATE = "finance/bank-accounts/{id}",
  FINANCE_BANK_ACCOUNT_DESTROY = "finance/bank-accounts/{id}",

  /* Diet & Detox */
  /* Diet & Detox - Nutrients */
  DIET_DETOX_NUTRIENT_INDEX = "diet-detox/nutrients",
  DIET_DETOX_NUTRIENT_SHOW = "diet-detox/nutrients/{id}",
  DIET_DETOX_NUTRIENT_STORE = "diet-detox/nutrients",
  DIET_DETOX_NUTRIENT_UPDATE = "diet-detox/nutrients/{id}",
  DIET_DETOX_NUTRIENT_DESTROY = "diet-detox/nutrients/{id}",
  DIET_DETOX_NUTRIENT_IMAGE_STORE = "diet-detox/nutrients/{id}/add-image",
  DIET_DETOX_RECIPE_CATEGORY_INDEX = "diet-detox/recipe-categories",
  DIET_DETOX_RECIPE_CATEGORY_SHOW = "diet-detox/recipe-categories/{id}",
  DIET_DETOX_RECIPE_CATEGORY_STORE = "diet-detox/recipe-categories",
  DIET_DETOX_RECIPE_CATEGORY_UPDATE = "diet-detox/recipe-categories/{id}",
  DIET_DETOX_RECIPE_CATEGORY_DESTROY = "diet-detox/recipe-categories/{id}",
  DIET_DETOX_RECIPE_INDEX = "diet-detox/recipes",
  DIET_DETOX_RECIPE_SHOW = "diet-detox/recipes/{id}",
  DIET_DETOX_RECIPE_STORE = "diet-detox/recipes",
  DIET_DETOX_RECIPE_UPDATE = "diet-detox/recipes/{id}",
  DIET_DETOX_RECIPE_DESTROY = "diet-detox/recipes/{id}",
  DIET_DETOX_RECIPE_IMAGE_STORE = "diet-detox/recipes/{id}/add-image",
  DIET_DETOX_DIET_CATEGORY_INDEX = "diet-detox/diet-categories",
  DIET_DETOX_DIET_CATEGORY_SHOW = "diet-detox/diet-categories/{id}",
  DIET_DETOX_DIET_CATEGORY_STORE = "diet-detox/diet-categories",
  DIET_DETOX_DIET_CATEGORY_UPDATE = "diet-detox/diet-categories/{id}",
  DIET_DETOX_DIET_CATEGORY_DESTROY = "diet-detox/diet-categories/{id}",
  DIET_DETOX_DETOX_CATEGORY_INDEX = "diet-detox/detox-categories",
  DIET_DETOX_DETOX_CATEGORY_SHOW = "diet-detox/detox-categories/{id}",
  DIET_DETOX_DETOX_CATEGORY_STORE = "diet-detox/detox-categories",
  DIET_DETOX_DETOX_CATEGORY_UPDATE = "diet-detox/detox-categories/{id}",
  DIET_DETOX_DETOX_CATEGORY_DESTROY = "diet-detox/detox-categories/{id}",
  DIET_DETOX_DIET_INDEX = "diet-detox/diets",
  DIET_DETOX_DIET_SHOW = "diet-detox/diets/{id}",
  DIET_DETOX_DIET_STORE = "diet-detox/diets",
  DIET_DETOX_DIET_UPDATE = "diet-detox/diets/{id}",
  DIET_DETOX_DIET_DESTROY = "diet-detox/diets/{id}",
  DIET_DETOX_DETOX_INDEX = "diet-detox/detoxes",
  DIET_DETOX_DETOX_SHOW = "diet-detox/detoxes/{id}",
  DIET_DETOX_DETOX_STORE = "diet-detox/detoxes",
  DIET_DETOX_DETOX_UPDATE = "diet-detox/detoxes/{id}",
  DIET_DETOX_DETOX_DESTROY = "diet-detox/detoxes/{id}",

  /* Customer */
  /* Customer */
  CUSTOMER_CUSTOMER_INDEX = "customer/customers",
  CUSTOMER_CUSTOMER_MEAL_PHOTOS = "customer/customers/{id}/meal-photos",
  CUSTOMER_CALENDAR_CUSTOMER_INDEX = "customer/customersCalendar",
  CUSTOMER_CUSTOMER_SHOW = "customer/customers/{id}",
  CUSTOMER_CUSTOMER_STORE = "customer/customers",
  CUSTOMER_CUSTOMER_UPDATE = "customer/customers/{id}",
  CUSTOMER_CUSTOMER_DESTROY = "customer/customers/{id}",
  CUSTOMER_CUSTOMER_PREFERENCES_INDEX = "customer/customers/{id}/preferences",
  CUSTOMER_CUSTOMER_PREFERENCES_UPDATE = "customer/customers/{id}/preferences",
  CUSTOMER_CUSTOMER_NOTES = "customer/customers/{id}/notes",
  CUSTOMER_CUSTOMER_PACKAGES = "customer/customers/{id}/packages",
  CUSTOMER_CUSTOMER_MEASUREMENT = "customer/customers/{id}/measurement",
  CUSTOMER_CUSTOMER_ASSIGNABLE_DIET_DETOX = "customer/customers/{id}/assignable/diet-detox/{diet-detox}",
  CUSTOMER_CUSTOMER_ASSIGNABLE_NUTRIENTS = "customer/customers/{id}/assignable/nutrients",
  CUSTOMER_CUSTOMER_ASSIGN_DIET_DETOX = "customer/customers/{id}/assign-diet-detox",
  CUSTOMER_CUSTOMER_OVERVIEW = "customer/customers/{id}/overview",
  /* Before After */
  CUSTOMER_BEFORE_AFTER_SHOW = "customer/before-afters/{id}",
  MEETS_INDEX = "meets",
  MEETS_DETAILS = "meets/details",
  MEETS_STORE = "meets",
  MEETS_UPDATE = "meets",
  MEETS_MEET_ABLE_CUSTOMERS = "meets/meet-able-customers",
  MEETS_CUSTOMERS_FOR_ASSIGN = "meets/customers-for-assign",
  MEETS_DELETE = "meets/{id}",
  /* Calendar */
  CALENDAR_INFO_CURRENT_TOTAL = "meets/info/current/total",
}
