import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    type: "text",
    class: "form-control dark form-control-solid",
    name: _ctx.name,
    placeholder: _ctx.placeholder,
    rows: "10",
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    value: _ctx.modelValue,
    style: {"resize":"none"},
    disabled: _ctx.disabled
  }, null, 40, _hoisted_1))
}