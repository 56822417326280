import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AppStoreMutations } from "@/store/enums/AppStoreEnum";

@Module
class AppModule extends VuexModule {
  loading = true;

  get isLoading(): boolean {
    return this.loading;
  }

  @Mutation
  [AppStoreMutations.SET_LOADING](loading) {
    this.loading = loading;
  }
}

export default AppModule;
