enum UserStoreActions {
  SET_U2M_TO_STORAGE = "setU2MToStorage",
  SHOW = "show",
}

enum UserStoreMutations {
  SET_USER_TO_STATE = "saveUserToState",
  DELETE_USER_STATE = "deleteUserState",
}

export { UserStoreMutations, UserStoreActions };
