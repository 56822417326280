export default class UserModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  registrationCompleted: boolean;
  can: Array<number>;
  avatar: string;

  constructor(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    registrationCompleted: boolean,
    can: Array<number>,
    avatar: string
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.registrationCompleted = registrationCompleted;
    this.can = can;
    this.avatar = avatar;
  }
}
