<template>
  <el-modal title="Kaynakları Seç" class="compare-items" modal-size="lg">
    <template v-slot:body>
      <search-input v-model="search" @search="onSearch" />
      <CardLoader v-if="loading" />
      <div class="food-list" v-else>
        <div class="item" v-for="(item, n) in items" :key="n">
          <div class="title">
            <p class="type type-body type-light">{{ item.label }}</p>
          </div>
          <div class="actions">
            <button
              class="bt"
              @click="onToggleSelect(item)"
              :class="isSelected(item) ? 'bt-ro-primary-bg' : 'bt-ro-gray-bg'"
            >
              <inline-svg
                v-if="isSelected(item)"
                :src="$prodImage.src('/media/icons/duotune/custom/check.svg')"
              />
              {{ isSelected(item) ? "Seçildi" : "Seç" }}
            </button>
          </div>
        </div>
      </div>

      <div class="py-5" v-if="!loading && items.length < 1">
        <WarningSection
          title="Ops..."
          description="Herhangi bir sonuç bulunamadı."
          image="illustrations/sigma-1/5-dark.png"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button @click="onSave" class="bt bt-ro-primary-light-bg">
          Karşılaştır
        </button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ElModal from "@/views/components/Modal.vue";
import SearchInput from "@/views/components/searchInput.vue";
import { isNumber } from "@/isNumber";
import ApiService from "@/core/services/ApiService";
import FoodModel from "@/store/models/FoodModel";
import { getUnitShortText } from "@/store/enums/FoodUnitEnum";
import WarningSection from "@/components/warning/WarningSection.vue";
import { CompareItemInterface } from "@/components/compare/CompareItemInterface";

export default defineComponent({
  name: "CompareItemsModal",
  methods: { getUnitShortText },
  components: {
    WarningSection,
    SearchInput,
    ElModal,
  },
  setup() {
    const store = useStore();
    const items = ref<Array<CompareItemInterface>>([]);
    const selectedItems = ref<Array<CompareItemInterface>>([]);
    const loading = ref(true);
    const search = ref<string>("");

    const onToggleSelect = (item: CompareItemInterface) => {
      const index: undefined | number = selectedItems.value.findIndex(
        (_item) => _item.value === item.value
      );
      if (index !== -1) {
        selectedItems.value.splice(index, 1);
        return;
      }
      selectedItems.value.push(item);
    };
    const onSearch = async () => {
      await fetch();
    };

    const fetch = async () => {
      loading.value = true;
      try {
        const result = await ApiService.U2MClient.get(
          store.getters.getModalData.fetchUrl,
          {
            params: {
              items_per_page: 9999999,
              search: search.value,
            },
          }
        );
        loading.value = false;

        items.value = result.data.data.map(
          store.getters.getModalData.dataManipulation
        );

        if (Array.isArray(store.getters.getModalData.items)) {
          const selected = store.getters.getModalData.items.map(
            store.getters.getModalData.dataManipulation
          );
          selected.forEach((item) => {
            const index = items.value.findIndex(
              (_item) => _item.value === item.value
            );
            if (index !== -1) {
              selectedItems.value.push(item);
            }
          });
        }
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    };

    const isSelected = computed(
      () => (item) =>
        selectedItems.value.findIndex((_item) => _item.value === item.value) !==
        -1
    );

    const onSave = async () => {
      loading.value = true;
      const filtered: Array<CompareItemInterface> = items.value.filter((item) =>
        selectedItems.value.find((_item) => item.value === _item.value)
      );

      const result: Array<object> = [];

      for (const item of filtered) {
        const res = await ApiService.U2MClient.get(
          store.getters.getModalData.showUrl.replaceAll("{id}", item.value)
        );
        result.push(res.data.data);
      }
      loading.value = false;

      store.commit("modalCallback", result);
      store.commit("closeModal");
    };

    const onDiscard = () => {
      store.commit("closeModal");
    };

    onMounted(async () => {
      await fetch();
    });

    return {
      items,
      onSave,
      onDiscard,
      onToggleSelect,
      isSelected,
      onSearch,
      isNumber,
      loading,
      search,
    };
  },
});
</script>

<style lang="scss">
.compare-items {
  .modal {
    .card {
      .card-body {
        .search-input {
          margin-bottom: 10px;
        }

        .food-list {
          border: 1px solid rgba(142, 154, 174, 0.08);
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          max-height: 400px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 15px;
            width: 100%;

            p {
              flex: 1;
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgba(142, 154, 174, 0.08);
            }

            .title {
              display: flex;
              gap: 10px;
              align-items: center;
            }

            .actions {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: flex-end;

              .quantity-input {
                display: flex;
                background: #f3f5f8;
                border-radius: 8px;
                padding: 0 0 0 12px;
                width: 160px;

                input {
                  background: transparent;
                  border: 0;
                  outline: none;
                  padding: 7px 0;
                  width: 100%;
                }

                .quantity-type {
                  background: rgba(142, 154, 174, 0.08);
                  padding: 7px;
                  border-radius: 0 8px 8px 0;
                }
              }

              .bt {
                padding: 8px 14px;
              }
            }

            @media screen and (max-width: 540px) {
              flex-direction: column;
              align-items: flex-start;
              .actions {
                flex-direction: column;
                width: 100%;
                align-items: stretch;

                .quantity-input {
                  width: 100%;
                }

                .bt {
                  order: -1;
                }
              }
            }
          }
        }

        select {
          padding: 11px 15px;
        }
      }
    }
  }
}
</style>
