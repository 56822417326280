export enum SessionTypeEnum {
  FACE_TO_FACE,
  ONLINE,
  DIET_CHANGE,
}

const getSessionLabel = (subscription: SessionTypeEnum) => {
  switch (subscription) {
    case SessionTypeEnum.ONLINE:
      return "Online";
    case SessionTypeEnum.DIET_CHANGE:
      return "Diyet Değişim";
    case SessionTypeEnum.FACE_TO_FACE:
      return "Yüz Yüze";
  }
};

const getSessionsForSelect = () => {
  return [
    {
      label: "Diyet Değişim",
      value: SessionTypeEnum.DIET_CHANGE,
    },
    {
      label: "Online",
      value: SessionTypeEnum.ONLINE,
    },
    {
      label: "Yüz Yüze",
      value: SessionTypeEnum.FACE_TO_FACE,
    },
  ];
};

export { getSessionLabel, getSessionsForSelect };
