<template>
  <el-modal
    title="Ödeme Ekle"
    @close="onDiscard"
    class="add-payment"
    modal-size="md"
  >
    <template v-slot:body>
      <CardLoader v-if="loading" />
      <div v-else class="lines-wrapper column">
        <div class="line-row">
          <p class="type type-body-medium">Tarih</p>
          <ResourceFormDatepicker v-model="date" />
        </div>
        <div class="line-row">
          <p class="type type-body-medium">Miktar</p>
          <ResourceFormInput placeholder="Miktar" v-model="amount" />
        </div>
        <div class="line-row">
          <p class="type type-body-medium">Ödeme Yöntemi</p>
          <ResourceFormSelect
            v-model="paymentMethod"
            :options="getMethodSelectItems"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button @click="onAdd" class="bt bt-ro-primary-light-bg">Ekle</button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import ElModal from "@/views/components/Modal.vue";
import ResourceFormDatepicker from "@/components/form/items/ResourceFormDatepicker.vue";
import ResourceFormInput from "@/components/form/items/ResourceFormInput.vue";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";
import {
  getPackagePaymentMethodForSelect,
  PackagePaymentMethodEnum,
} from "@/store/enums/PackagePaymentMethodEnum";
import ResourceFormSelect from "@/components/form/items/ResourceFormSelect.vue";

export default defineComponent({
  name: "AddPaymentModal",
  components: {
    ResourceFormSelect,
    ResourceFormInput,
    ResourceFormDatepicker,
    ElModal,
  },
  setup() {
    const store = useStore();
    const amount = ref<number>(0);
    const paymentMethod = ref<PackagePaymentMethodEnum>(
      PackagePaymentMethodEnum.ONLINE
    );
    const date = ref();
    const loading = ref<boolean>(false);

    const getMethodSelectItems = computed(() =>
      getPackagePaymentMethodForSelect()
    );

    const onAdd = async () => {
      loading.value = true;
      await ApiService.U2MClient.post(
        Endpoints.FINANCE_CUSTOMER_PACKAGE_PAYMENT_STORE.replaceAll(
          "{id}",
          store.getters.getModalData.id
        ),
        {
          amount: amount.value,
          date: date.value.split("T")[0],
          payment_method: paymentMethod.value,
        }
      );
      loading.value = false;
      store.commit("modalCallback", true);
      store.commit("closeModal");
    };

    const onDiscard = () => {
      store.commit("modalCallback", false);
      store.commit("closeModal");
    };

    return {
      onAdd,
      onDiscard,
      date,
      amount,
      paymentMethod,
      getMethodSelectItems,
      loading,
    };
  },
});
</script>

<style lang="scss">
.add-food-modal {
  .modal {
    .card {
      .card-body {
        .search-input {
          margin-bottom: 10px;
        }

        .food-list {
          border: 1px solid rgba(142, 154, 174, 0.08);
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          max-height: 400px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 15px;
            width: 100%;

            p {
              flex: 1;
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgba(142, 154, 174, 0.08);
            }

            .title {
              display: flex;
              gap: 10px;
              align-items: center;
            }

            .actions {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: flex-end;

              .quantity-input {
                display: flex;
                background: #f3f5f8;
                border-radius: 8px;
                padding: 0 0 0 12px;
                width: 160px;

                input {
                  background: transparent;
                  border: 0;
                  outline: none;
                  padding: 7px 0;
                  width: 100%;
                }

                .quantity-type {
                  background: rgba(142, 154, 174, 0.08);
                  padding: 7px;
                  border-radius: 0 8px 8px 0;
                }
              }

              .bt {
                padding: 8px 14px;
              }
            }

            @media screen and (max-width: 540px) {
              flex-direction: column;
              align-items: flex-start;
              .actions {
                flex-direction: column;
                width: 100%;
                align-items: stretch;

                .quantity-input {
                  width: 100%;
                }

                .bt {
                  order: -1;
                }
              }
            }
          }
        }

        select {
          padding: 11px 15px;
        }
      }
    }
  }
}
</style>
