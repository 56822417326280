function passwordMask(number: string | number): string {
  number = String(number);
  const length = number.length - 4;
  const middle = "*".repeat(length);
  return (
    number[0] +
    number[1] +
    number[2] +
    middle +
    number[number.length - 3] +
    number[number.length - 2] +
    number[number.length - 1]
  );
}

function getTimeBySeconds(duration): string {
  const timer = duration;
  let minutes, seconds;

  minutes = Math.floor(timer / 60);
  seconds = Math.floor(timer % 60);

  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return minutes + ":" + seconds;
}

export { passwordMask, getTimeBySeconds };
