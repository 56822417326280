import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/messages",
    redirect: "messages/all-messages",
    name: "messages",
    meta: {
      module: RoleModuleEnum.MESSAGE,
    },
    children: [
      {
        path: "messages",
        component: () => import("@/views/messages/messages/Messages.vue"),
        name: "messages",
        meta: {
          pageTitle: "Tüm Mesajlar",
        },
      },
      {
        path: "quick-messages",
        component: () =>
          import("@/views/messages/quick-message/QuickMessageIndex.vue"),
        name: "quick-messages",
        meta: {
          pageTitle: "Hızlı Mesajlar",
        },
      },
      {
        path: "quick-message-add",
        component: () =>
          import("@/views/messages/quick-message/QuickMessageEdit.vue"),
        name: "quick-message-add",
        meta: {
          pageTitle: "Yeni Hızlı Mesaj",
        },
      },
      {
        path: "quick-message/:id",
        component: () =>
          import("@/views/messages/quick-message/QuickMessageEdit.vue"),
        name: "quick-message/:id",
        meta: {
          pageTitle: "Hızlı Mesaj",
        },
      },
      {
        path: "quick-answers",
        component: () =>
          import("@/views/messages/quick-answer/QuickAnswerIndex.vue"),
        name: "quick-answers",
        meta: {
          pageTitle: "Hızlı Cevaplar",
        },
      },
      {
        path: "quick-answer-add",
        component: () =>
          import("@/views/messages/quick-answer/QuickAnswerEdit.vue"),
        name: "quick-answer-add",
        meta: {
          pageTitle: "Yeni Hızlı Cevap",
        },
      },
      {
        path: "quick-answer/:id",
        component: () =>
          import("@/views/messages/quick-answer/QuickAnswerEdit.vue"),
        name: "quick-answer/:id",
        meta: {
          pageTitle: "Hızlı Cevap",
        },
      },
    ],
  },
];
