<template>
  <!-- TODO design will be changed -->
  <DatePicker
    time-picker
    placeholder="Saat Seç"
    v-model="value"
    locale="tr"
    cancel-text="İptal"
    select-text="Onayla"
  ></DatePicker>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ResourceFormTimepicker",
  props: {
    modelValue: {
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const value = ref();
    const formatted = ref(false);
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let date = new Date(props.modelValue);
      value.value = {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: 0,
      };
    });

    const formatTime = (timeObj) => {
      const hours = String(timeObj?.hours).padStart(2, "0");
      const minutes = String(timeObj?.minutes).padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    watch(value, (val) => {
      emit("update:modelValue", formatTime(val));
    });

    return {
      value,
    };
  },
});
</script>
