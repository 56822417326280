<template>
  <textarea
    type="text"
    class="form-control dark form-control-solid"
    :name="name"
    :placeholder="placeholder"
    rows="10"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
    style="resize: none"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ResourceFormTextarea",
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
});
</script>
