import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/notification",
    name: "notification",
    component: () => import("@/views/notification/SendAppNotification.vue"),
    meta: {
      pageTitle: "Bildirim",
      module: RoleModuleEnum.NOTIFICATION,
    },
  },
];
