import { LoginEnums } from "@/store/enums/StoreEnums";

const Login = {
  state: () => ({
    loginState: "login",
    userPhoneNumber: "",
    passCode: "222222",
    userCode: "",
    validated: false,
  }),
  mutations: {
    [LoginEnums.SEND_AUTH_CODE](state) {
      state.loginState = "auth-step";
    },
    [LoginEnums.NEW_MEMBER](state) {
      state.loginState = "sign-up";
    },
    [LoginEnums.RESET](state) {
      state.loginState = "login";
    },
    [LoginEnums.UPDATE_PHONE](state, value) {
      state.userPhoneNumber = value;
    },
    [LoginEnums.RESET_CODE](state) {
      state.userCode = "";
    },
    [LoginEnums.UPDATE_CODE](state, code) {
      state.userCode = code;
    },
  },
  getters: {
    userCodeLength(state, getters) {
      return getters.userCode.length;
    },
    userCode(state) {
      return state.userCode;
    },
    passCode(state) {
      return state.passCode;
    },
  },
};

export default Login;
