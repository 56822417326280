import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createBlock(_component_DatePicker, {
    "time-picker": "",
    placeholder: "Saat Seç",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    locale: "tr",
    "cancel-text": "İptal",
    "select-text": "Onayla"
  }, null, 8, ["modelValue"]))
}