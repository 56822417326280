import { FoodUnitEnum } from "@/store/enums/FoodUnitEnum";

export enum PackagePaymentMethodEnum {
  ONLINE = 0,
  CASH = 1,
  TRANSFER = 2,
  SYSTEM = 3,
}

const getPackagePaymentMethodLabel = (status: PackagePaymentMethodEnum) => {
  switch (status) {
    case PackagePaymentMethodEnum.ONLINE:
      return "Online";
    case PackagePaymentMethodEnum.CASH:
      return "Nakit";
    case PackagePaymentMethodEnum.TRANSFER:
      return "Havale";
    case PackagePaymentMethodEnum.SYSTEM:
      return "Sistem";
  }
};
const getPackagePaymentMethodForSelect = () => {
  return [
    {
      label: getPackagePaymentMethodLabel(PackagePaymentMethodEnum.ONLINE),
      value: PackagePaymentMethodEnum.ONLINE,
    },
    {
      label: getPackagePaymentMethodLabel(PackagePaymentMethodEnum.CASH),
      value: PackagePaymentMethodEnum.CASH,
    },
    {
      label: getPackagePaymentMethodLabel(PackagePaymentMethodEnum.TRANSFER),
      value: PackagePaymentMethodEnum.TRANSFER,
    },
    {
      label: getPackagePaymentMethodLabel(PackagePaymentMethodEnum.SYSTEM),
      value: PackagePaymentMethodEnum.SYSTEM,
    },
  ];
};

export { getPackagePaymentMethodLabel, getPackagePaymentMethodForSelect };
