import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "step-2" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = ["id", "onChange"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "title type type-body type-light" }, "Tip Seç*", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSessionsForSelect(), (type, n) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "form-check lg form-check-custom form-check-solid",
          key: n
        }, [
          _createElementVNode("input", {
            class: "form-check-input",
            name: "type",
            type: "radio",
            id: type.label,
            onChange: ($event: any) => (_ctx.selectType(type.value))
          }, null, 40, _hoisted_3),
          _createElementVNode("label", {
            class: "form-check-label",
            for: type.label
          }, _toDisplayString(type.label), 9, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}