export enum RoleModuleEnum {
  HOME,
  CUSTOMER,
  DIET_DETOX,
  CALENDAR,
  MESSAGE,
  PACKAGE,
  FINANCE,
  TEAM,
  CALL_CENTER,
  SETTINGS,
  NOTIFICATION,
}

const getModulesForSelect = () => {
  return [
    {
      label: "Ana Sayfa",
      value: RoleModuleEnum.HOME,
    },
    {
      label: "Danışan",
      value: RoleModuleEnum.CUSTOMER,
    },
    {
      label: "Diyet - Detoks",
      value: RoleModuleEnum.DIET_DETOX,
    },
    {
      label: "Takvim",
      value: RoleModuleEnum.CALENDAR,
    },
    {
      label: "Mesaj",
      value: RoleModuleEnum.MESSAGE,
    },
    {
      label: "Paket",
      value: RoleModuleEnum.PACKAGE,
    },
    {
      label: "Finans",
      value: RoleModuleEnum.FINANCE,
    },
    {
      label: "Ekip",
      value: RoleModuleEnum.TEAM,
    },
    {
      label: "Çağrı Merkezi",
      value: RoleModuleEnum.CALL_CENTER,
    },
    {
      label: "Ayarlar",
      value: RoleModuleEnum.SETTINGS,
    },
    {
      label: "Bildirim",
      value: RoleModuleEnum.NOTIFICATION,
    },
  ];
};

const gerRouteNameOfModules = (module: RoleModuleEnum) => {
  switch (module) {
    case RoleModuleEnum.HOME:
      return "home";
    case RoleModuleEnum.CUSTOMER:
      return "customers";
    case RoleModuleEnum.DIET_DETOX:
      return "diet-detox";
    case RoleModuleEnum.CALENDAR:
      return "calendar";
    case RoleModuleEnum.MESSAGE:
      return "messages";
    case RoleModuleEnum.PACKAGE:
      return "packages";
    case RoleModuleEnum.FINANCE:
      return "finance";
    case RoleModuleEnum.TEAM:
      return "team";
    case RoleModuleEnum.CALL_CENTER:
      return "call-center";
    case RoleModuleEnum.SETTINGS:
      return "settings";
    case RoleModuleEnum.NOTIFICATION:
      return "notification";
  }
};

export { getModulesForSelect, gerRouteNameOfModules };
