import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "step-3",
  class: "w-100"
}
const _hoisted_2 = { class: "time-pickers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceFormDatepicker = _resolveComponent("ResourceFormDatepicker")!
  const _component_ResourceFormTimepicker = _resolveComponent("ResourceFormTimepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "type type-body type-light dark" }, "Seans Tarihi*", -1)),
    _createVNode(_component_ResourceFormDatepicker, {
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event))
    }, null, 8, ["modelValue"]),
    _cache[4] || (_cache[4] = _createElementVNode("p", { class: "type type-body type-light dark" }, "Seans Saatleri*", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ResourceFormTimepicker, {
        modelValue: _ctx.startedAt,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startedAt) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_ResourceFormTimepicker, {
        modelValue: _ctx.endAt,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endAt) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}