import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/HomePage.vue"),
    meta: {
      pageTitle: "Ana Sayfa",
      module: RoleModuleEnum.HOME,
    },
  },
];
