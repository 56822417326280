const Meals = {
  state: () => ({
    foodList: [
      {
        photo: "/media/meals/water-cup.png",
        name: "Water",
        amount: "1 Glass",
      },
      {
        photo: "/media/meals/water-cup.png",
        name: "Water 2",
        amount: "1 Glass",
      },
      {
        photo: "/media/meals/water-cup.png",
        name: "Water 3",
        amount: "1 Glass",
      },
      {
        photo: "/media/meals/bread-slice.png",
        name: "Bread",
        amount: "1 slice",
      },
      {
        photo: "/media/meals/bread-slice.png",
        name: "Bread 2",
        amount: "1 slice",
      },
      {
        photo: "/media/meals/bread-slice.png",
        name: "Bread",
        amount: "1 Slice",
      },
    ],
  }),
  getters: {
    getFoods(state) {
      return state.foodList;
    },
  },
};

export default Meals;
