enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
}

enum Modals {
  EVALUATION_DETAIL_MODAL = "evaluationDetailModal",
  ADD_FOOD_MODAL = "addFoodModal",
  MEAL_PHOTO_MODAL = "mealPhotoModal",
  PHOTO_PREVIEW_MODAL = "beforePhotoModal",
  DELETE_CONFIRM_MODAL = "deleteConfirmModal",
  NEW_MEET_MODAL = "newMeetModal",
  ADD_PAYMENT_MODAL = "addPaymentModal",
  ADD_NOTE_MODAL = "addNoteModal",
  MESSAGE_DETAIL_MODAL = "messageDetailModal",
  ADD_PHOTO_MODAL = "addPhotoModal",
  INVOICE_DETAIL_MODAL = "invoiceDetailModal",
  DEFINE_PACKAGE_MODAL = "definePackageModal",
  NEW_MEET_MODAL_2 = "newMeetModal2",
  ADD_ALTERNATIVE_FOOD = "addAlternativeFood",
}

enum LoginEnums {
  SEND_AUTH_CODE = "SEND_AUTH_CODE",
  RESET = "RESET",
  NEW_MEMBER = "NEW_MEMBER",
  UPDATE_PHONE = "UPDATE_PHONE",
  RESET_CODE = "RESET_CODE",
  UPDATE_CODE = "UPDATE_CODE",
  VALID_EMAIL = "VALID_EMAIL",
}

export { Actions, Mutations, Modals, LoginEnums };
