import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/calendar",
    redirect: "calendar/all-meetings",
    name: "calendar",
    meta: {
      module: RoleModuleEnum.CALENDAR,
    },
    children: [
      {
        path: "all-meetings",
        component: () => import("@/views/calendar/Calendar.vue"),
        name: "all-meetings",
        meta: {
          pageTitle: "All Meetings",
        },
      },
      {
        path: "info",
        component: () => import("@/views/calendar/CalendarInfo.vue"),
        name: "info",
        meta: {
          pageTitle: "Info ",
        },
      },
    ],
  },
];
