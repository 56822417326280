<template>
  <div id="step-3" class="w-100">
    <p class="type type-body type-light dark">Seans Tarihi*</p>
    <ResourceFormDatepicker v-model="date" />
    <p class="type type-body type-light dark">Seans Saatleri*</p>
    <div class="time-pickers">
      <ResourceFormTimepicker v-model="startedAt" />
      <ResourceFormTimepicker v-model="endAt" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import ResourceFormDatepicker from "@/components/form/items/ResourceFormDatepicker.vue";
import ResourceFormTimepicker from "@/components/form/items/ResourceFormTimepicker.vue";

export default defineComponent({
  name: "Step-3",
  components: { ResourceFormDatepicker, ResourceFormTimepicker },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const date = ref("");
    const startedAt = ref("");
    const endAt = ref("");

    onMounted(() => {
      watch([date, startedAt, endAt], ([newDate, newStartedAt, newEndAt]) => {
        emit("update:modelValue", {
          date: newDate,
          startedAt: newStartedAt,
          endAt: newEndAt,
        });
      });
    });

    return {
      date,
      startedAt,
      endAt,
    };
  },
});
</script>

<style lang="scss">
.el-date-editor.el-input {
  height: 42px;
  width: 100%;
}

.el-input__wrapper {
  background-color: #f3f5f8;
}

#step-3 {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    line-height: 34px;
  }

  .time {
    display: flex;
    gap: 10px;

    select {
      height: 42px;
    }
  }
  .time-pickers {
    display: flex;
    gap: 12px;
    width: 100%;
    .dp__main {
      width: 50%;
    }
  }
}
</style>
