<template>
  <input
    :type="type"
    class="form-control dark form-control-solid"
    :name="name"
    :placeholder="placeholder"
    autocomplete="false"
    v-model="formValue"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ResourceFormInput",
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const formValue = ref("");

    watch(formValue, (val) => {
      emit("update:modelValue", val);
    });

    onMounted(() => {
      formValue.value = props.modelValue?.toString() || "";
    });

    return {
      formValue,
    };
  },
});
</script>
