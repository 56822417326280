<template>
  <div id="step-3" class="w-100">
    <p class="type type-body type-light dark">Seans Tarihi*</p>
    <ResourceFormDatepicker v-model="date" />
    <p class="type type-body type-light dark">Yeni Tarih*</p>
    <ResourceFormDatepicker v-model="newDate" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import ResourceFormDatepicker from "@/components/form/items/ResourceFormDatepicker.vue";

export default defineComponent({
  name: "Step_3_3",
  components: { ResourceFormDatepicker },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const date = ref("");
    const newDate = ref("");

    onMounted(() => {
      watch([date, newDate], (val) => {
        emit("update:modelValue", {
          meet_date: val[0],
          new_date: val[1],
        });
      });
    });

    return {
      date,
      newDate,
    };
  },
});
</script>

<style lang="scss">
.el-date-editor.el-input {
  height: 42px;
  width: 100%;
}

.el-input__wrapper {
  background-color: #f3f5f8;
}

#step-3 {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    line-height: 34px;
  }

  .time {
    display: flex;
    gap: 10px;

    select {
      height: 42px;
    }
  }
}
</style>
