<template>
  <el-modal
    title="Detay"
    @close="$store.commit('closeModal')"
    class="message-detail"
    modal-size="lg"
  >
    <template v-slot:body>
      <div class="details-header">
        <div>
          <p class="type type-body type-light dark">{{modalData.name}}</p>
          <div>
            <a :href="'tel://'+modalData.phone" class="gray type type-caption-semibold">
              <inline-svg src="media/icons/duotune/custom/mobile-button.svg" />
              {{modalData.phone}}
            </a>
            <a :href="'mailto:'+modalData.email" class="gray type type-caption-semibold">
              <inline-svg src="media/icons/duotune/custom/envelope.svg" />
              {{modalData.email}}
            </a>
          </div>
        </div>
        <p class="type type-caption-semibold gray">{{modalData.created_at}}</p>
      </div>
      <div class="message-details">
        <p class="type type-title-small">{{modalData.subject}}</p>
        <p class="type type-caption gray">
          {{modalData.message}}
        </p>
      </div>
    </template>
  </el-modal>
</template>

<script  lang="ts">
import ElModal from "@/views/components/Modal.vue";
import {  useStore } from "vuex";
import { defineComponent, ref } from "vue";


export default defineComponent({
  name: "ContactModal",
  components: { ElModal},
  setup() {

    const store = useStore();
    const modalData = ref(store.getters.getModalData);
    console.log(modalData)
    return {
      modalData
    };
  },
});
</script>

<style scoped></style>
