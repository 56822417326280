<template>
  <div id="step-2">
    <p class="title type type-body type-light">Tip Seç*</p>
    <div class="form">
      <div
        class="form-check lg form-check-custom form-check-solid"
        v-for="(type, n) in getSessionsForSelect()"
        :key="n"
      >
        <input
          class="form-check-input"
          name="type"
          type="radio"
          :id="type.label"
          @change="selectType(type.value)"
        />
        <label class="form-check-label" :for="type.label">
          {{ type.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  SessionTypeEnum,
  getSessionsForSelect,
} from "@/store/enums/SessionTypeEnum";

export default defineComponent({
  name: "Step-2",
  components: {},
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    function selectType(eventType) {
      emit("update:modelValue", eventType);
    }

    return {
      props,
      SessionTypeEnum,
      selectType,
      getSessionsForSelect,
    };
  },
});
</script>

<style lang="scss">
#step-2 {
  .title {
    margin-bottom: 10px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 0;

      label {
        font-size: 16px;
        line-height: 34px;
      }
    }
  }
}
</style>
