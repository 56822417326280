export enum PackageTypeEnum {
  diet,
  detox,
  faceToFace,
}

export function getPackageType(type: PackageTypeEnum) {
  switch (type) {
    case PackageTypeEnum.diet:
      return "Diet";
      break;
    case PackageTypeEnum.detox:
      return "Detox";
      break;
    case PackageTypeEnum.faceToFace:
      return "Yüz yüze diyet";
      break;
    default:
      break;
  }
}

export function getPackageColor(type: PackageTypeEnum) {
  switch (type) {
    case PackageTypeEnum.diet:
      return "yellow";
      break;
    case PackageTypeEnum.detox:
      return "purple";
      break;
    case PackageTypeEnum.faceToFace:
      return "blue";
      break;
    default:
      break;
  }
}
