import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleOpen && _ctx.handleOpen(...args))),
    class: "position-relative"
  }, [
    _createVNode(_component_multi_select, {
      class: "high",
      placeholder: "Seç",
      options: _ctx.options,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      "no-results-text": "Öğe Bulunamadı",
      "no-options-text": "Liste Boş",
      "can-deselect": false
    }, null, 8, ["options", "modelValue"]),
    _createVNode(_component_inline_svg, {
      ref: "svg",
      class: "dropdown-arrow",
      src: _ctx.$prodImage.src('/media/icons/duotune/custom/angle-down.svg'),
      style: _normalizeStyle({ transform: _ctx.isOpen ? 'rotate(180deg)' : '' })
    }, null, 8, ["src", "style"])
  ]))
}