import { vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "name", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    class: "form-control dark form-control-solid",
    name: _ctx.name,
    placeholder: _ctx.placeholder,
    autocomplete: "false",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue) = $event)),
    disabled: _ctx.disabled
  }, null, 8, _hoisted_1)), [
    [_vModelDynamic, _ctx.formValue]
  ])
}