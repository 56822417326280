<template>
  <div id="step-1" class="w-100">
    <search-input v-model="search" @search="onSearch" />
    <div
      v-if="customers.length > 0 && !searching"
      class="customers-list"
      ref="customerListDom"
    >
      <div class="item" v-for="(customer, n) in customers" :key="n">
        <div class="left">
          <img
            :src="customer.for_table.media?.avatar"
            alt=""
            width="34"
            height="34"
            class="rounded-circle"
          />
          <div class="name-wrapper">
            <p class="type type-body type-light dark">
              {{ customer.for_table.user }}
            </p>
            <p class="type type-caption-semibold gray">
              {{ customer.for_table.package }}
            </p>
          </div>
        </div>
        <div class="right">
          <button
            @click.prevent="toggleSelected(n)"
            class="bt"
            :class="
              selectedCustomers.includes(customer.id)
                ? 'bt-ro-primary-bg'
                : 'bt-light-gray-bordered'
            "
          >
            <inline-svg
              v-show="selectedCustomers.includes(customer.id)"
              src="media/icons/duotune/custom/check.svg"
            />
            <span v-if="selectedCustomers.includes(customer.id)">
              Seçildi
            </span>
            <span v-else> Seç </span>
          </button>
        </div>
      </div>
    </div>
    <CardLoader height="70" v-if="loading" />

    <div class="py-5" v-if="!loading && customers.length < 1">
      <WarningSection
        title="Ops..."
        description="Herhangi bir sonuç bulunamadı."
        image="illustrations/sigma-1/5-dark.png"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, toRef } from "vue";
import SearchInput from "@/views/components/searchInput.vue";
import MeetNewEventController from "@/core/controllers/MeetNewEventController";
import CustomerModel from "@/store/models/CustomerModel";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";

export default defineComponent({
  name: "Step-1",
  components: { SearchInput },
  props: {
    controller: { type: MeetNewEventController, required: true },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const customers = ref<Array<object>>([]);
    const selectedCustomers = ref<Array<number>>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const searching = ref<boolean>(false);
    const page = ref<number>(0);
    const customerListDom = ref();

    const fetch = async (query?: string) => {
      loading.value = true;
      page.value++;
      console.log(page.value);
      const result = await ApiService.U2MClient.get(
        Endpoints.CUSTOMER_CALENDAR_CUSTOMER_INDEX,
        {
          params: { items_per_page: 10, search: query, page: page.value },
        }
      );

      if (query !== null && query !== "" && page.value === 1) {
        customers.value = result.data.data;
      } else {
        customers.value = [...customers.value, ...result.data.data];
      }
      loading.value = false;
      searching.value = false;

      setTimeout(() => {
        addEvent();
      }, 1);
    };

    const addEvent = () => {
      if (customerListDom.value != null) {
        console.log(customerListDom.value);
        customerListDom.value.addEventListener("scroll", async (e) => {
          if (
            customerListDom.value.scrollTop +
              customerListDom.value.clientHeight >=
            customerListDom.value.scrollHeight
          ) {
            if (!loading.value) {
              await fetch();
            }
          }
        });
      }
    };

    onMounted(() => {
      fetch();
      addEvent();
    });

    const toggleSelected = (index) => {
      const id = customers.value[index]["id"];
      if (selectedCustomers.value.includes(id)) {
        const indexOfCustomer = selectedCustomers.value.indexOf(id);
        selectedCustomers.value.splice(indexOfCustomer, 1);
      } else {
        selectedCustomers.value.push(id);
      }
      emit("update:modelValue", selectedCustomers.value);
    };

    const onSearch = (query) => {
      searching.value = true;
      page.value = 0;
      fetch(query);
    };

    return {
      customers,
      loading,
      toggleSelected,
      selectedCustomers,
      onSearch,
      search,
      searching,
      customerListDom,
    };
  },
});
</script>

<style lang="scss">
#step-1 {
  .step-header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > div {
      &:first-child {
        gap: 10px;
        display: flex;

        .multiselect {
          min-width: 150px;

          .multiselect-input {
            height: 42px;
          }
        }
      }

      &:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        > div {
          display: flex;
          gap: 10px;
        }
      }
    }
  }

  .customers-list {
    border: 1px solid rgba(142, 154, 174, 0.08);
    border-radius: 12px;
    margin-top: 10px;
    max-height: 332px;
    overflow-y: scroll;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      &:not(:last-child) {
        border-bottom: 1px solid #8e9aae14;
      }

      .left {
        display: flex;

        .name-wrapper {
          margin-left: 15px;

          p:first-of-type {
            margin-bottom: 4px;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;

        .bt {
          padding: 8px 14px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      @media screen and (max-width: 554px) {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        .right {
          width: 100%;

          .bt {
            flex: 1;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
