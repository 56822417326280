import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-100 d-flex justify-content-center align-items-center m-0 p-0",
    style: _normalizeStyle({ height: (_ctx.height ?? 200) + 'px' })
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "overlay-layer bg-opacity-5" }, [
      _createElementVNode("div", {
        class: "spinner-border text-primary",
        role: "status"
      }, [
        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
      ])
    ], -1)
  ]), 4))
}