import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "step-2",
  class: "step"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceFormDatepicker = _resolveComponent("ResourceFormDatepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", {
      class: "type type-body-medium dark",
      style: {"padding":"12px 0","margin-bottom":"10px"}
    }, " İlk Randevu Tarihini Seç ", -1)),
    _createVNode(_component_ResourceFormDatepicker, {
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event))
    }, null, 8, ["modelValue"])
  ]))
}