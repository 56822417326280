import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/SettingsPage.vue"),
    redirect: "settings/activities",
    meta: {
      module: RoleModuleEnum.SETTINGS,
    },
    children: [
      {
        path: "activities",
        name: "settings-activities",
        component: () => import("@/views/settings/tabs/Activities.vue"),
        meta: {
          pageTitle: "Aktiviteler",
        },
      },
      {
        path: "clinic-locations",
        name: "settings-clinic-locations",
        component: () => import("@/views/settings/tabs/ClinicLocations.vue"),
        meta: {
          pageTitle: "Klinikler",
        },
      },
      {
        path: "jobs",
        name: "settings-jobs",
        component: () => import("@/views/settings/tabs/Jobs.vue"),
        meta: {
          pageTitle: "Meslekler",
        },
      },
      {
        path: "health-problems",
        name: "settings-health-problems",
        component: () => import("@/views/settings/tabs/HealthProblems.vue"),
        meta: {
          pageTitle: "Sağlık Problemleri",
        },
      },
      {
        path: "diseases-problems",
        name: "settings-diseases-problems",
        component: () => import("@/views/settings/tabs/Diseases.vue"),
        meta: {
          pageTitle: "Hastalıklar",
        },
      },
      {
        path: "allergies",
        name: "settings-allergies",
        component: () => import("@/views/settings/tabs/Allergies.vue"),
        meta: {
          pageTitle: "Alerjiler",
        },
      },
      {
        path: "eating-habits",
        name: "settings-eating-habits",
        component: () => import("@/views/settings/tabs/EatingHabits.vue"),
        meta: {
          pageTitle: "Beslenme Alışkanlıkları",
        },
      },
      {
        path: "physical-activity-status",
        name: "settings-physical-activity-status",
        component: () =>
          import("@/views/settings/tabs/PhysicalActivityStatus.vue"),
        meta: {
          pageTitle: "Fiziksel Aktivite Durumları",
        },
      },
      {
        path: "caffeine-consumption",
        name: "settings-caffeine-consumption",
        component: () =>
          import("@/views/settings/tabs/CaffeineConsumption.vue"),
        meta: {
          pageTitle: "Kafein Tüketimi",
        },
      },
      {
        path: "water-consumption",
        name: "settings-water-consumption",
        component: () => import("@/views/settings/tabs/WaterConsumption.vue"),
        meta: {
          pageTitle: "Su Tüketimi",
        },
      },
      {
        path: "sleep-patterns",
        name: "sleep-patterns",
        component: () => import("@/views/settings/tabs/SleepPatterns.vue"),
        meta: {
          pageTitle: "Uyku Düzenleri",
        },
      },
      {
        path: "extra-cases",
        name: "extra-cases",
        component: () => import("@/views/settings/tabs/ExtraCase.vue"),
        meta: {
          pageTitle: "Ekstra Durumlar",
        },
      },
      {
        path: "diet-compatibilities",
        name: "diet-compatibilities",
        component: () => import("@/views/settings/tabs/DietCompatibility.vue"),
        meta: {
          pageTitle: "Diyet Uyumlulukları",
        },
      },
      {
        path: "weekly-exercise-counts",
        name: "weekly-exercise-counts",
        component: () =>
          import("@/views/settings/tabs/WeeklyExerciseCount.vue"),
        meta: {
          pageTitle: "Haftalık Egzersiz Durumları",
        },
      },
      {
        path: "meals",
        name: "settings-meals",
        component: () => import("@/views/settings/tabs/Meals.vue"),
        meta: {
          pageTitle: "Öğünler",
        },
      },
    ],
  },
];
