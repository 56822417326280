import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "details-header" }
const _hoisted_2 = { class: "type type-body type-light dark" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "type type-caption-semibold gray" }
const _hoisted_6 = { class: "message-details" }
const _hoisted_7 = { class: "type type-title-small" }
const _hoisted_8 = { class: "type type-caption gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    title: "Detay",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('closeModal'))),
    class: "message-detail",
    "modal-size": "lg"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.modalData.name), 1),
          _createElementVNode("div", null, [
            _createElementVNode("a", {
              href: 'tel://'+_ctx.modalData.phone,
              class: "gray type type-caption-semibold"
            }, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/custom/mobile-button.svg" }),
              _createTextVNode(" " + _toDisplayString(_ctx.modalData.phone), 1)
            ], 8, _hoisted_3),
            _createElementVNode("a", {
              href: 'mailto:'+_ctx.modalData.email,
              class: "gray type type-caption-semibold"
            }, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/custom/envelope.svg" }),
              _createTextVNode(" " + _toDisplayString(_ctx.modalData.email), 1)
            ], 8, _hoisted_4)
          ])
        ]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.modalData.created_at), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.modalData.subject), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.modalData.message), 1)
      ])
    ]),
    _: 1
  }))
}