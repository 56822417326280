<template>
  <el-modal title="Tarif Ekle" class="add-food-modal" modal-size="lg">
    <template v-slot:body>
      <search-input v-model="search" @search="onSearch" />
      <div
        v-if="foods.length > 0 && !searching"
        id="food-list"
        class="food-list"
        ref="foodListDom"
      >
        <div class="item" v-for="(item, n) in foods" :key="n">
          <div class="title">
            <img :src="item.photo" width="44" height="44" alt="" />
            <p class="type type-body type-light">{{ item.name }}</p>
          </div>
          <div class="actions">
            <div class="quantity-input" v-if="isSelected(item)">
              <input
                type="text"
                placeholder="Miktar"
                @input="onUpdateQuantity(item, $event.target.value)"
                :value="getQuantity(item)"
              />
              <div class="quantity-type">{{ getUnitShortText(item.unit) }}</div>
            </div>
            <button
              class="bt"
              @click="onToggleSelect(item)"
              :class="isSelected(item) ? 'bt-ro-primary-bg' : 'bt-ro-gray-bg'"
            >
              <inline-svg
                v-if="isSelected(item)"
                :src="$prodImage.src('/media/icons/duotune/custom/check.svg')"
              />
              {{ isSelected(item) ? "Seçildi" : "Seç" }}
            </button>
          </div>
        </div>
      </div>
      <CardLoader height="70" v-if="loading" />

      <div class="py-5" v-if="!loading && foods.length < 1">
        <WarningSection
          title="Ops..."
          description="Herhangi bir sonuç bulunamadı."
          image="illustrations/sigma-1/5-dark.png"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button @click="onSave" class="bt bt-ro-primary-light-bg">
          Kaydet
        </button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ElModal from "@/views/components/Modal.vue";
import SearchInput from "@/views/components/searchInput.vue";
import { isNumber } from "@/isNumber";
import ApiService from "@/core/services/ApiService";
import RecipeModel from "@/store/models/RecipeModel";
import { getUnitShortText } from "@/store/enums/FoodUnitEnum";
import WarningSection from "@/components/warning/WarningSection.vue";
import { Endpoints } from "@/store/enums/network/Endpoints";
import { useRoute } from "vue-router";
import FoodModel from "@/store/models/FoodModel";

export default defineComponent({
  name: "AddRecipeModal",
  methods: { getUnitShortText },
  components: {
    WarningSection,
    SearchInput,
    ElModal,
  },
  setup() {
    const store = useStore();
    const foods = ref<Array<RecipeModel>>([]);
    const selectedFoods = ref<Array<RecipeModel>>([]);
    const loading = ref(true);
    const search = ref<string>("");
    const searching = ref<boolean>(false);
    const route = useRoute();
    const page = ref<number>(0);
    const foodListDom = ref();

    const onUpdateQuantity = (item: RecipeModel, quantity) => {
      const indexOfFood = selectedFoods.value.findIndex(
        (_item) => _item.id === item.id
      );
      if (indexOfFood !== -1) {
        selectedFoods.value[indexOfFood].quantity = quantity;
      }
      item.quantity = quantity;
    };

    const onToggleSelect = (item: RecipeModel) => {
      const index: undefined | number = selectedFoods.value.findIndex(
        (_item) => _item.id === item.id
      );
      if (index !== -1) {
        selectedFoods.value.splice(index, 1);
        return;
      }
      selectedFoods.value.push(item);
    };

    const controller = new AbortController();

    const onSearch = async () => {
      searching.value = true;
      page.value = 0;
      await fetch();
    };

    const fetch = async () => {
      loading.value = true;
      page.value++;
      try {
        const result = await ApiService.U2MClient.get(
          Endpoints.DIET_DETOX_RECIPE_INDEX,
          {
            params: {
              items_per_page: 10,
              search: search.value,
              page: page.value,
            },
            signal: controller.signal,
          }
        );
        loading.value = false;
        searching.value = false;

        if (search.value !== null && search.value !== "" && page.value === 1) {
          foods.value = result.data.data.map(
            (item) =>
              new FoodModel(
                item.id,
                item.name,
                item.unit,
                item.calorie,
                item.media.thumb
              )
          );
        } else {
          foods.value = [
            ...foods.value,
            ...result.data.data.map(
              (item) =>
                new FoodModel(
                  item.id,
                  item.name,
                  item.unit,
                  item.calorie,
                  item.media.thumb
                )
            ),
          ];
        }

        selectedFoods.value.forEach((item) => {
          const index = foods.value.findIndex((_item) => _item.id === item.id);
          if (index !== -1) {
            foods.value[index].quantity = item.quantity;
          }
        });

        setTimeout(() => {
          addEvent();
        }, 1);
      } catch (e) {
        console.log(e);
        searching.value = false;
        loading.value = false;
      }
    };

    const isSelected = computed(
      () => (item) =>
        selectedFoods.value.findIndex((_item) => _item.id === item.id) !== -1
    );

    const getQuantity = computed(() => (item) => {
      const selectedIndex = selectedFoods.value.findIndex(
        (_item) => _item.id === item.id
      );

      if (selectedIndex !== -1) {
        return selectedFoods.value[selectedIndex].quantity;
      }
      return item.quantity;
    });

    const onSave = () => {
      const filtered: Array<RecipeModel> = selectedFoods.value.filter(
        (_food) => _food.quantity !== undefined && _food.quantity !== ""
      );
      store.commit("modalCallback", filtered);
      store.commit("closeModal");
    };

    const onDiscard = () => {
      store.commit("closeModal");
    };

    const addEvent = () => {
      if (foodListDom.value != null) {
        foodListDom.value.addEventListener("scroll", async (e) => {
          if (
            foodListDom.value.scrollTop + foodListDom.value.clientHeight >=
            foodListDom.value.scrollHeight
          ) {
            if (!loading.value) {
              await fetch();
            }
          }
        });
      }
    };

    onMounted(async () => {
      selectedFoods.value = Array.isArray(store.getters.getModalData.foods)
        ? store.getters.getModalData.foods
        : [];
      await fetch();
      addEvent();
    });

    return {
      foods,
      onSave,
      onDiscard,
      onToggleSelect,
      isSelected,
      onSearch,
      onUpdateQuantity,
      isNumber,
      loading,
      search,
      searching,
      foodListDom,
      getQuantity,
    };
  },
});
</script>

<style lang="scss">
.add-food-modal {
  .modal {
    .card {
      .card-body {
        .search-input {
          margin-bottom: 10px;
        }

        .food-list {
          border: 1px solid rgba(142, 154, 174, 0.08);
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          max-height: 400px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 15px;
            width: 100%;

            p {
              flex: 1;
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgba(142, 154, 174, 0.08);
            }

            .title {
              display: flex;
              gap: 10px;
              align-items: center;
            }

            .actions {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: flex-end;

              .quantity-input {
                display: flex;
                background: #f3f5f8;
                border-radius: 8px;
                padding: 0 0 0 12px;
                width: 160px;

                input {
                  background: transparent;
                  border: 0;
                  outline: none;
                  padding: 7px 0;
                  width: 100%;
                }

                .quantity-type {
                  background: rgba(142, 154, 174, 0.08);
                  padding: 7px;
                  border-radius: 0 8px 8px 0;
                }
              }

              .bt {
                padding: 8px 14px;
              }
            }

            @media screen and (max-width: 540px) {
              flex-direction: column;
              align-items: flex-start;
              .actions {
                flex-direction: column;
                width: 100%;
                align-items: stretch;

                .quantity-input {
                  width: 100%;
                }

                .bt {
                  order: -1;
                }
              }
            }
          }
        }

        select {
          padding: 11px 15px;
        }
      }
    }
  }
}
</style>
