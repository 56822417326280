export enum FoodUnitEnum {
  PIECE,
  KILO,
  LITER,
  WATER_GLASS,
  GRAMS,
  TEA_GLASS,
  TABLESPOON,
  DESSERT_SPOON,
  TEA_SPOON,
  STAMP,
  CUP_LITTLE,
  CUP,
  BUNDLE,
  BRANCH,
  SLICE,
  LADLE,
  FINGER_UP,
  LEAF,
}

const getUnitLabel = (unit: FoodUnitEnum) => {
  switch (unit) {
    case FoodUnitEnum.PIECE:
      return "Adet";
    case FoodUnitEnum.KILO:
      return "Kilo";
    case FoodUnitEnum.LITER:
      return "Litre";
    case FoodUnitEnum.WATER_GLASS:
      return "Su Bardağı";
    case FoodUnitEnum.GRAMS:
      return "Gram";
    case FoodUnitEnum.TEA_GLASS:
      return "Çay Bardağı";
    case FoodUnitEnum.TABLESPOON:
      return "Yemek Kaşığı";
    case FoodUnitEnum.DESSERT_SPOON:
      return "Tatlı Kaşığı";
    case FoodUnitEnum.TEA_SPOON:
      return "Çay Kaşığı";
    case FoodUnitEnum.STAMP:
      return "Kase";
    case FoodUnitEnum.CUP_LITTLE:
      return "Fincan";
    case FoodUnitEnum.CUP:
      return "Kupa";
    case FoodUnitEnum.BUNDLE:
      return "Demet";
    case FoodUnitEnum.BRANCH:
      return "Dal";
    case FoodUnitEnum.SLICE:
      return "Dilim";
    case FoodUnitEnum.LADLE:
      return "Kepçe";
    case FoodUnitEnum.LEAF:
      return "Yaprak";
  }
};

const getUnitShortText = (unit: FoodUnitEnum) => {
  switch (unit) {
    case FoodUnitEnum.PIECE:
      return "adet";
    case FoodUnitEnum.KILO:
      return "kg";
    case FoodUnitEnum.LITER:
      return "lt";
    case FoodUnitEnum.WATER_GLASS:
      return "su bardağı";
    case FoodUnitEnum.GRAMS:
      return "gr";
    case FoodUnitEnum.TEA_GLASS:
      return "çay bardağı";
    case FoodUnitEnum.TABLESPOON:
      return "yemek kaşığı";
    case FoodUnitEnum.DESSERT_SPOON:
      return "tatlı kaşığı";
    case FoodUnitEnum.TEA_SPOON:
      return "çay kaşığı";
    case FoodUnitEnum.STAMP:
      return "kase";
    case FoodUnitEnum.CUP_LITTLE:
      return "fincan";
    case FoodUnitEnum.CUP:
      return "kupa";
    case FoodUnitEnum.BUNDLE:
      return "demet";
    case FoodUnitEnum.BRANCH:
      return "dal";
    case FoodUnitEnum.SLICE:
      return "dilim";
    case FoodUnitEnum.LADLE:
      return "kepçe";
    case FoodUnitEnum.LEAF:
      return "yaprak";
  }
};

const getUnitByLabel = (label: string) => {
  switch (label) {
    case "Adet":
      return FoodUnitEnum.PIECE;
    case "Kilo":
      return FoodUnitEnum.KILO;
    case "Litre":
      return FoodUnitEnum.LITER;
    case "Su Bardağı":
      return FoodUnitEnum.WATER_GLASS;
    case "Gram":
      return FoodUnitEnum.GRAMS;
    case "Çay Bardağı":
      return FoodUnitEnum.TEA_GLASS;
    case "Yemek Kaşığı":
      return FoodUnitEnum.TABLESPOON;
    case "Tatlı Kaşığı":
      return FoodUnitEnum.DESSERT_SPOON;
    case "Çay Kaşığı":
      return FoodUnitEnum.TEA_SPOON;
    case "Kase":
      return FoodUnitEnum.STAMP;
    case "Fincan":
      return FoodUnitEnum.CUP_LITTLE;
    case "Kupa":
      return FoodUnitEnum.CUP;
    case "Demet":
      return FoodUnitEnum.BUNDLE;
    case "Dal":
      return FoodUnitEnum.BRANCH;
    case "Dilim":
      return FoodUnitEnum.SLICE;
    case "Kepçe":
      return FoodUnitEnum.LADLE;
    case "Yaprak":
      return FoodUnitEnum.LEAF;
  }
};

const getUnitLabelsForSelect = () => {
  return [
    {
      label: "Adet",
      value: FoodUnitEnum.PIECE,
    },
    {
      label: "Kilo",
      value: FoodUnitEnum.KILO,
    },
    {
      label: "Litre",
      value: FoodUnitEnum.LITER,
    },
    {
      label: "Su Bardağı",
      value: FoodUnitEnum.WATER_GLASS,
    },
    {
      label: "Gram",
      value: FoodUnitEnum.GRAMS,
    },
    {
      label: "Çay Bardağı",
      value: FoodUnitEnum.TEA_GLASS,
    },
    {
      label: "Yemek Kaşığı",
      value: FoodUnitEnum.TABLESPOON,
    },
    {
      label: "Tatlı Kaşığı",
      value: FoodUnitEnum.DESSERT_SPOON,
    },
    {
      label: "Çay Kaşığı",
      value: FoodUnitEnum.TEA_SPOON,
    },
    {
      label: "Kase",
      value: FoodUnitEnum.STAMP,
    },
    {
      label: "Fincan",
      value: FoodUnitEnum.CUP_LITTLE,
    },
    {
      label: "Kupa",
      value: FoodUnitEnum.CUP,
    },
    {
      label: "Demet",
      value: FoodUnitEnum.BUNDLE,
    },
    {
      label: "Dal",
      value: FoodUnitEnum.BRANCH,
    },
    {
      label: "Dilim",
      value: FoodUnitEnum.SLICE,
    },
    {
      label: "Kepçe",
      value: FoodUnitEnum.LADLE,
    },
    {
      label: "Parmak Kadar",
      value: FoodUnitEnum.FINGER_UP,
    },
    {
      label: "Yaprak",
      value: FoodUnitEnum.LEAF,
    },
  ];
};

export {
  getUnitLabel,
  getUnitShortText,
  getUnitLabelsForSelect,
  getUnitByLabel,
};
