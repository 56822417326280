import { createApp } from "vue";
import App from "./App.vue";
import Multiselect from "@vueform/multiselect";
import CardLoader from "@/components/loader/CardLoader.vue";
import Notice from "@/components/Notice.vue";
import WarningSection from "@/components/warning/WarningSection.vue";
import "@/core/firebase";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router";
import store from "./store";
import ElementPlus from "element-plus";
import VueSplide from "@splidejs/vue-splide";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import "@/core/plugins/prismjs";
import "bootstrap";
import ProdImage from "@/helpers/ProdImage";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueSplide);
app.component("MultiSelect", Multiselect);
app.component("CardLoader", CardLoader);
app.component("NoticeComp", Notice);
app.component("DatePicker", Datepicker);
app.component("WarningSection", WarningSection);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");

app.config.globalProperties.$prodImage = ProdImage;
