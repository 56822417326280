<template>
  <form
    data-kt-search-element="form"
    class="w-100 position-relative"
    autocomplete="off"
  >
    <div class="w-100 position-relative">
      <span
        class="search-icon svg-icon position-absolute top-50 translate-middle-y ms-5"
      >
        <inline-svg
          :src="
            $prodImage.src('/media/icons/duotune/custom/magnifying-glass.svg')
          "
        />
      </span>

      <input
        type="text"
        class="search-input form-control form-control-solid"
        style="padding-left: 40px"
        name="search"
        @input="onChange($event)"
        @keydown.enter="$event.preventDefault()"
        :placeholder="placeholder"
        data-kt-search-element="input"
        :search-dropdown="searchDropdown"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "searchInput",
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: "Ara...",
    },
    searchDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "search"],
  setup(props, { emit }) {
    const searchTimer = ref(0);
    const interval = ref();

    const onChange = (e) => {
      emit("update:modelValue", e.target.value);
      search(e.target.value);
    };

    const search = (query) => {
      searchTimer.value = 1;
      clearInterval(interval.value);
      interval.value = setInterval(() => {
        searchTimer.value--;
        if (searchTimer.value === 0) {
          emit("search", query);
          clearInterval(interval.value);
        }
      }, 1000);
    };

    return {
      onChange,
    };
  },
});
</script>

<style lang="scss">
.search-input {
  background-color: #f3f5f8;
  padding-left: 40px;
}

.search-icon svg {
  width: 14px !important;
  height: 14px !important;
}
</style>
