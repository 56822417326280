<template>
  <el-modal
    title="Fotoğraf"
    @close="closeModal"
    class="photo-modal"
    modal-size="md"
  >
    <template v-slot:body>
      <img :src="data" alt="" />
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/views/components/Modal.vue";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "PhotoPreviewModal",
  components: { ElModal },
  setup() {
    const closeModal = () => {
      console.log("something happens here");
    };
    const store = useStore();
    const data = ref(store.getters.getModalData);
    return {
      closeModal,
      data,
    };
  },
});
</script>
