import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "type type-title-small dark" }
const _hoisted_5 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal", _ctx.modalSize]),
          role: "dialog",
          "aria-labelledby": "modalTitle",
          "aria-describedby": "modalDescription"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", {
                class: "close",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
              }, [
                _createVNode(_component_inline_svg, {
                  src: _ctx.$prodImage.src('/media/icons/duotune/custom/xmark.svg')
                }, null, 8, ["src"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "body")
            ]),
            _renderSlot(_ctx.$slots, "footer")
          ])
        ], 2)
      ])
    ]),
    _: 3
  }))
}