<template>
  <div @click="handleOpen" class="position-relative">
    <multi-select
      class="high"
      placeholder="Seç"
      :options="options"
      v-model="value"
      no-results-text="Öğe Bulunamadı"
      no-options-text="Liste Boş"
      :can-deselect="false"
    />
    <inline-svg
      ref="svg"
      class="dropdown-arrow"
      :src="$prodImage.src('/media/icons/duotune/custom/angle-down.svg')"
      :style="{ transform: isOpen ? 'rotate(180deg)' : '' }"
    ></inline-svg>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ResourceFormSelect",
  props: {
    options: {
      type: Object,
      required: true,
    },
    modelValue: {
      required: true,
    },
    canDeselect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const value = ref();
    const isOpen = ref(false);
    const handleOpen = () => {
      isOpen.value = !isOpen.value;
      console.log(isOpen.value);
    };
    onMounted(() => {
      value.value = props.modelValue;
    });

    watch(value, (val) => {
      emit("update:modelValue", val);
    });

    return {
      value,
      handleOpen,
      isOpen,
    };
  },
});
</script>

<style lang="scss">
.multiselect-clear {
  margin-right: 16px;
}
.dropdown-arrow {
  position: absolute;
  top: 40%;
  right: 10px;
  color: #8e9aae;
  transition: all 200ms ease-in-out;
  pointer-events: none;
}

.multiselect-caret {
  display: none;
}
</style>
