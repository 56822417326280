import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-px text-center" }
const _hoisted_2 = { class: "fs-2x fw-bold mb-5" }
const _hoisted_3 = { class: "text-gray-400 fs-5 fw-semobold" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.description), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        src: _ctx.$prodImage.src('/media/' + _ctx.image),
        alt: "",
        class: "mw-100 mh-300px"
      }, null, 8, _hoisted_5)
    ])
  ], 64))
}