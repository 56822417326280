<template>
  <el-modal title="Yeni Seans" @close="onClose" class="new-meet-modal" modal-size="lg">
    <template v-slot:body>
      <div
        class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
        id="kt_create_account_stepper"
        ref="verticalWizardRef"
      >
        <div class="row w-100">
          <div class="col-lg-4 mb-lg-0 mb-10">
            <div class="stepper-nav">
              <div
                class="stepper-item"
                v-for="(step, index) in steps"
                :key="index"
                :class="index === currentStep ? 'current' : index < currentStep ? 'completed' : ''"
                data-kt-stepper-element="nav"
              >
                <div class="stepper-wrapper">
                  <!--begin::Icon-->
                  <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">{{ index + 1 }}</span>
                  </div>
                  <!--end::Icon-->

                  <!--begin::Label-->
                  <div class="stepper-label">
                    <h3 class="stepper-title">{{ step.title }}</h3>
                    <div class="stepper-desc fw-semobold">
                      {{ step.subtitle }}
                    </div>
                  </div>
                  <!--end::Label-->
                </div>
                <!--begin::Line-->
                <div v-if="index !== steps.length - 1" class="stepper-line h-50px"></div>
                <!--end::Line-->
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <form novalidate="novalidate" id="kt_create_account_form" @submit="handleStep">
              <div
                :class="index === currentStep ? 'current' : index < currentStep ? 'completed' : ''"
                data-kt-stepper-element="content"
                v-for="(step, index) in steps"
                :key="index"
              >
                <CardLoader v-if="loading" />
                <component v-else :is="step.component" v-model="data[index]" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button v-if="currentStep > 0" class="bt bt-light-gray-bordered me-2" @click="prevStep">Geri</button>
        <button class="bt bt-ro-primary-light-bg" @click="nextStep">İleri</button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import ElModal from "@/views/components/Modal.vue";
import Step1 from "@/views/calendar/components/NewMeetModal/Step1.vue";
import Step2 from "@/views/calendar/components/NewMeetModal/Step2.vue";
import Step3 from "@/views/calendar/components/NewMeetModal/Step3.vue";
import Step4 from "@/views/calendar/components/NewMeetModal/Step4.vue";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "NewMeetModal",
  components: { Step1, Step2, Step3, Step4, ElModal },
  setup() {
    const currentStep = ref<number>(0);
    const steps = ref([
      {
        component: "step-1",
        title: "Danışan",
        subtitle: "Danışanları Seç",
      },
      {
        component: "step-2",
        title: "Tip",
        subtitle: "Seans Tipi Belirle",
      },
      {
        component: "step-3",
        title: "Tarih & Saat",
        subtitle: "Seans Tarihini Seç",
      },
      {
        component: "step-4",
        title: "Tamamlandı",
        subtitle: "Seanslar Oluşturuldu!",
      },
    ]);

    const loading = ref<boolean>(false);
    const data = ref([[], null, null]);
    const router = useRouter();

    const store = useStore();

    function handleStep(e) {
      e.preventDefault();
    }

    const nextStep = async () => {
      if (currentStep.value === steps.value.length - 2) {
        loading.value = true;
        const payload = {
          customers: data.value[0],
          meet_type: data.value[1],
          //@ts-ignore
          meet_date: data.value[2]?.date,
          //@ts-ignore
          start_at: data.value[2]?.startedAt,
          //@ts-ignore
          end_at: data.value[2]?.endAt,
        };
        const allValuesDefined = Object.values(payload).every(
          (value) => value !== null && value !== undefined && value !== ""
        );
        if (allValuesDefined) {
          await ApiService.U2MClient.post(Endpoints.MEETS_STORE, payload);
          currentStep.value++;
        }
        loading.value = false;
      }
      if (currentStep.value === steps.value.length - 1) {
        router.go(0);
      }
      if (currentStep.value === 0 && (data.value[0]?.length ?? 0) > 0) {
        currentStep.value++;
      } else if (currentStep.value === 1 && data.value[1] !== null) {
        currentStep.value++;
      } else if (currentStep.value === 2 && data.value[2] !== null) {
        return;
      } else if (currentStep.value === steps.value.length - 2) {
        currentStep.value++;
      }
    };

    function prevStep() {
      currentStep.value--;
    }

    const onClose = () => {
      store.commit("closeModal");
    };

    return {
      handleStep,
      currentStep,
      steps,
      nextStep,
      prevStep,
      onClose,
      data,
      loading,
    };
  },
});
</script>
