export default class TokenModel {
  token: string | null;
  expireAt: Date | null;

  constructor(token: string | null, expireAt: Date | null) {
    this.token = token;
    this.expireAt = expireAt;
  }

  public isExpired(): boolean {
    if (this.expireAt !== null) return !(this.expireAt!.getTime() > Date.now());
    return false;
  }

  public available(): boolean {
    return this.token !== null && !this.isExpired();
  }
}
