import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-kt-search-element": "form",
  class: "w-100 position-relative",
  autocomplete: "off"
}
const _hoisted_2 = { class: "w-100 position-relative" }
const _hoisted_3 = { class: "search-icon svg-icon position-absolute top-50 translate-middle-y ms-5" }
const _hoisted_4 = ["placeholder", "search-dropdown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_inline_svg, {
          src: 
            _ctx.$prodImage.src('/media/icons/duotune/custom/magnifying-glass.svg')
          
        }, null, 8, ["src"])
      ]),
      _createElementVNode("input", {
        type: "text",
        class: "search-input form-control form-control-solid",
        style: {"padding-left":"40px"},
        name: "search",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event))),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($event.preventDefault()), ["enter"])),
        placeholder: _ctx.placeholder,
        "data-kt-search-element": "input",
        "search-dropdown": _ctx.searchDropdown
      }, null, 40, _hoisted_4)
    ])
  ]))
}