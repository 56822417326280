<template>
  <el-modal title="Not Ekle" class="add-note" modal-size="lg">
    <template v-slot:body>
      <div class="lines-wrapper">
        <div class="line-row" v-if="!isAdminNote">
          <p class="dark type type-body-medium">Durum</p>
          <ResourceFormSelect
            v-model="activeStatus"
            placeholder="Durum"
            :options="status"
          />
        </div>
        <div class="line-row" v-if="!isAdminNote">
          <p class="dark type type-body-medium">Sonraki Arama Tarihi</p>
          <ResourceFormDatepicker placeholder="Seç" v-model="date" />
        </div>
        <div class="line-row align-items-start">
          <p class="dark type type-body-medium" style="margin-top: 12px">Not</p>
          <ResourceFormTextarea placeholder="Not" v-model="note" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button class="bt bt-ro-primary-light-bg" @click="onSave">
          Kaydet
        </button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ElModal from "@/views/components/Modal.vue";
import {
  CallCenterStatusEnum,
  getStatusForSelect,
} from "@/store/enums/CallCenterStatusEnum";
import ResourceFormSelect from "@/components/form/items/ResourceFormSelect.vue";
import ResourceFormDatepicker from "@/components/form/items/ResourceFormDatepicker.vue";
import ResourceFormTextarea from "@/components/form/items/ResourceFormTextarea.vue";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";

export default defineComponent({
  name: "AddCallCenterNoteModal",
  components: {
    ResourceFormTextarea,
    ResourceFormDatepicker,
    ResourceFormSelect,
    ElModal,
  },
  setup() {
    const store = useStore();
    const loading = ref(true);
    const search = ref<string>("");
    const isAdminNote = ref<boolean>(false);

    const activeStatus = ref<CallCenterStatusEnum | null>(null);
    const note = ref<string>("");
    const date = ref<Date>();

    const status = ref<Array<object>>(getStatusForSelect());

    const onSave = async () => {
      loading.value = true;
      if (!isAdminNote.value) {
        await ApiService.U2MClient.post(
          Endpoints.CALL_CENTER_CUSTOMER_STORE.replaceAll(
            "{id}",
            store.getters.getModalData.user_id
          ),
          {
            call_result_state: activeStatus.value,
            note: note.value,
            next_call_date: date.value,
          }
        );
      } else {
        await ApiService.U2MClient.post(
          Endpoints.CUSTOMER_CUSTOMER_NOTES.replaceAll(
            "{id}",
            store.getters.getModalData.user_id
          ),
          {
            note: note.value,
          }
        );
      }
      loading.value = false;

      store.commit("modalCallback");
      store.commit("closeModal");
    };

    onMounted(() => {
      isAdminNote.value = !!store.getters.getModalData.adminNote;
    });

    return {
      onSave,
      loading,
      search,
      activeStatus,
      status,
      note,
      date,
      isAdminNote,
    };
  },
});
</script>

<style lang="scss">
.compare-items {
  .modal {
    .card {
      .card-body {
        .search-input {
          margin-bottom: 10px;
        }

        .food-list {
          border: 1px solid rgba(142, 154, 174, 0.08);
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          max-height: 400px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 15px;
            width: 100%;

            p {
              flex: 1;
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgba(142, 154, 174, 0.08);
            }

            .title {
              display: flex;
              gap: 10px;
              align-items: center;
            }

            .actions {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: flex-end;

              .quantity-input {
                display: flex;
                background: #f3f5f8;
                border-radius: 8px;
                padding: 0 0 0 12px;
                width: 160px;

                input {
                  background: transparent;
                  border: 0;
                  outline: none;
                  padding: 7px 0;
                  width: 100%;
                }

                .quantity-type {
                  background: rgba(142, 154, 174, 0.08);
                  padding: 7px;
                  border-radius: 0 8px 8px 0;
                }
              }

              .bt {
                padding: 8px 14px;
              }
            }

            @media screen and (max-width: 540px) {
              flex-direction: column;
              align-items: flex-start;
              .actions {
                flex-direction: column;
                width: 100%;
                align-items: stretch;

                .quantity-input {
                  width: 100%;
                }

                .bt {
                  order: -1;
                }
              }
            }
          }
        }

        select {
          padding: 11px 15px;
        }
      }
    }
  }
}
</style>
