import CustomerModel from "@/store/models/CustomerModel";
import { EventTypeEnum } from "@/store/enums/EventTypeEnum";

export default class MeetNewEventController {
  customers: Array<CustomerModel>;
  eventType: EventTypeEnum;
  eventDate: Date | null;
  eventStartAt: string | null;
  eventEndAt: string | null;

  constructor(
    customers: Array<CustomerModel>,
    eventType: EventTypeEnum,
    eventDate: Date | null,
    eventStartAt: string | null,
    eventEndAt: string | null
  ) {
    this.customers = customers;
    this.eventType = eventType;
    this.eventDate = eventDate;
    this.eventStartAt = eventStartAt;
    this.eventEndAt = eventEndAt;
  }
}
