<template>
  <transition name="modal-fade">
    <div class="modal-wrapper">
      <div
        class="modal"
        :class="modalSize"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="card">
          <div class="card-header">
            <h2 class="type type-title-small dark">{{ title }}</h2>
            <div class="close" @click="onClose">
              <inline-svg
                :src="$prodImage.src('/media/icons/duotune/custom/xmark.svg')"
              />
            </div>
          </div>
          <div class="card-body">
            <slot name="body"></slot>
          </div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { useStore } from "vuex";

type ModalSize = "lg" | "md";

export default defineComponent({
  name: "ElModal",
  components: {},
  props: {
    title: String,
    modalSize: {
      type: String as PropType<ModalSize>,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const onClose = () => {
      emit("close");
      store.commit("closeModal");
    };
    return {
      onClose,
    };
  },
});
</script>

<!--Transition styles-->
<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>

<style lang="scss">
.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 11000;
  background: rgba(#181c32, 0.3);

  .modal {
    display: block;
    height: max-content;
    position: unset;

    &.lg {
      width: 980px;
      @media screen and (max-width: 500px) {
        margin: 0 20px;
      }
    }

    &.md {
      width: 480px;
    }

    .card {
      .card-header {
        padding: 27px 30px;
        margin: 0;
        align-items: center;

        .close {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(142, 154, 174, 0.08);
          color: #8e9aae;
          border-radius: 10px;
          cursor: pointer;
          transition: all 300ms ease-in-out;

          svg {
            path {
              transition: all 300ms ease-in-out;
            }
          }

          &:hover {
            background: rgba(255, 97, 97, 0.12);

            svg {
              path {
                fill: #ff6161;
              }
            }
          }
        }
      }

      .card-body {
        padding: 30px;
      }

      .card-footer {
        padding: 20px 30px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #eff0f2;
      }
    }
  }
}
</style>
