enum AuthStoreActions {
  LOGIN = "login",
  SEND_OTP = "sendOtp",
  INIT_RECAPTCHA = "initRecaptcha",
  SIGN_IN_WITH_PHONE = "signInWithPhone",
  VERIFY_CODE = "verifyCode",
  TWO_FACTORY_VERIFY = "twoFactorVerify",
  LOGIN_WITH_TOKEN = "loginWithToken",
}

enum AuthStoreMutations {
  SET_PHONE = "setPhone",
  SET_CONFIRM_RESULT = "setConfirmResult",
}

export { AuthStoreMutations, AuthStoreActions };
