import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/diet-detox",
    redirect: "diet-detox/diet-lists",
    name: "diet-detox",
    meta: {
      module: RoleModuleEnum.DIET_DETOX,
    },
    children: [
      /* Recipe Groups */
      {
        path: "recipe-groups-add",
        component: () =>
          import("@/views/diet-detox/recipe-groups/RecipeGroupEdit.vue"),
        name: "recipe-group-add",
        meta: {
          pageTitle: "Yeni Tarif Grubu",
        },
      },
      {
        path: "recipe-groups/:id",
        component: () =>
          import("@/views/diet-detox/recipe-groups/RecipeGroupEdit.vue"),
        name: "recipe-group-detail",
        meta: {
          pageTitle: "Tarif Grubu",
        },
      },
      {
        path: "recipe-groups",
        component: () =>
          import("@/views/diet-detox/recipe-groups/RecipeGroupsIndex.vue"),
        name: "recipe-group",
        meta: {
          pageTitle: "Tarif Grupları",
        },
      },
      /* Diet Groups */

      /* Diet Groups */
      {
        path: "diet-list-groups-add",
        component: () =>
          import("@/views/diet-detox/diet-list-groups/DietListGroupEdit.vue"),
        name: "add-new-diet-lists-group",
        meta: {
          pageTitle: "Yeni Diyet Listesi Grubu",
        },
      },
      {
        path: "diet-list-groups/:id",
        component: () =>
          import("@/views/diet-detox/diet-list-groups/DietListGroupEdit.vue"),
        name: "diet-list-group-detail",
        meta: {
          pageTitle: "Diyet Listesi Grubu",
        },
      },
      {
        path: "diet-list-groups",
        component: () =>
          import("@/views/diet-detox/diet-list-groups/DietListGroupsIndex.vue"),
        name: "diet-lists-group",
        meta: {
          pageTitle: "Diyet Listesi Grupları",
        },
      },
      /* Diet Groups */

      /* Detox Groups */
      {
        path: "detox-groups-add",
        component: () =>
          import("@/views/diet-detox/detox-groups/DetoxGroupEdit.vue"),
        name: "detox-group-add",
        meta: {
          pageTitle: "Yeni Detoks Grubu",
        },
      },
      {
        path: "detox-groups/:id",
        component: () =>
          import("@/views/diet-detox/detox-groups/DetoxGroupEdit.vue"),
        name: "detox-group-edit",
        meta: {
          pageTitle: "Detoks Grubu",
        },
      },
      {
        path: "detox-groups",
        component: () =>
          import("@/views/diet-detox/detox-groups/DetoxGroupsIndex.vue"),
        name: "detox-groups",
        meta: {
          pageTitle: "Detoks Grupları",
        },
      },
      /* Detox Groups */

      /* Foods */
      {
        path: "foods-add",
        component: () => import("@/views/diet-detox/foods/FoodEdit.vue"),
        name: "food-add",
        meta: {
          pageTitle: "Yeni Besin",
        },
      },
      {
        path: "foods/:id",
        component: () => import("@/views/diet-detox/foods/FoodEdit.vue"),
        name: "food-edit",
        meta: {
          pageTitle: "Besin",
        },
      },
      {
        path: "foods",
        component: () => import("@/views/diet-detox/foods/FoodsIndex.vue"),
        name: "foods",
        meta: {
          pageTitle: "Besinler",
        },
      },
      /* Foods */

      /* Recipes */
      {
        path: "recipes",
        component: () => import("@/views/diet-detox/recipes/RecipesIndex.vue"),
        name: "recipes",
        meta: {
          pageTitle: "Tarifler",
        },
      },
      {
        path: "recipes/:id",
        component: () => import("@/views/diet-detox/recipes/RecipeEdit.vue"),
        name: "recipe-edit",
        meta: {
          pageTitle: "Tarif",
        },
      },
      {
        path: "recipes-add",
        component: () => import("@/views/diet-detox/recipes/RecipeEdit.vue"),
        name: "recipe-add",
        meta: {
          pageTitle: "Yeni Tarif",
        },
      },
      /* Recipes */

      /* Diets */
      {
        path: "diet-lists",
        component: () => import("@/views/diet-detox/diets/DietsIndex.vue"),
        name: "diet-lists",
        meta: {
          pageTitle: "Diyet Listeleri",
        },
      },
      {
        path: "diet-lists/:id",
        component: () => import("@/views/diet-detox/diets/DietEdit.vue"),
        name: "diet-list-edit",
        meta: {
          pageTitle: "Diyet Listesi",
        },
      },
      {
        path: "diet-lists-add",
        component: () => import("@/views/diet-detox/diets/DietEdit.vue"),
        name: "diet-list-add",
        meta: {
          pageTitle: "Yeni Diyet Listesi",
        },
      },
      /* Diets */

      /* Detoxes */
      {
        path: "detox-waters",
        component: () => import("@/views/diet-detox/detox/DetoxIndex.vue"),
        name: "detox-waters",
        meta: {
          pageTitle: "Detoks Suları",
        },
      },
      {
        path: "detox-waters/:id",
        component: () => import("@/views/diet-detox/detox/DetoxEdit.vue"),
        name: "detox-water-edit",
        meta: {
          pageTitle: "Detoks Suyu",
        },
      },
      {
        path: "detox-water-add",
        component: () => import("@/views/diet-detox/detox/DetoxEdit.vue"),
        name: "detox-water-add",
        meta: {
          pageTitle: "Yeni Detoks Suyu",
        },
      },
      /* Detoxes */
    ],
  },
];
