<template>
  <div id="step-2" class="step">
    <p
      class="type type-body-medium dark"
      style="padding: 12px 0; margin-bottom: 10px"
    >
      Paket Seç
    </p>
    <ResourceFormSelect v-model="selectedPackage" :options="packages" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import ResourceFormSelect from "@/components/form/items/ResourceFormSelect.vue";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";

export default defineComponent({
  name: "Step-2",
  components: { ResourceFormSelect },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const packages = ref<Array<object>>([]);
    const selectedPackage = ref(null);
    const loading = ref<boolean>(true);

    const fetch = async () => {
      loading.value = true;
      const result = await ApiService.U2MClient.get(Endpoints.PACKAGE_INDEX, {
        params: {
          items_per_page: 99999,
          status: 1,
        },
      });
      packages.value = result.data.data.map((e) => ({
        value: e.id,
        label: e.name,
      }));
      loading.value = false;
    };

    onMounted(() => {
      fetch();

      watch(selectedPackage, (val) => {
        emit("update:modelValue", val);
      });
    });

    return {
      loading,
      packages,
      selectedPackage,
    };
  },
});
</script>

<style lang="scss"></style>
