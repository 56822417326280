<template>
  <div id="step-4" class="w-100">
    <div class="d-flex align-items-center flex-column" style="gap: 10px">
      <img src="/media/illustrations/two-stupid-women.png" alt="" />
      <h2
        class="dark type type-body type-light d-flex align-items-center"
        style="line-height: 34px; gap: 10px"
      >
        <inline-svg
          style="color: #80b918"
          src="media/icons/duotune/custom/check.svg"
        />
        İşlem başarılı bir şekilde tamamlandı!
      </h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Step-4",
});
</script>
