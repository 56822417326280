export default [
  {
    path: "/login",
    component: () => import("@/views/auth/LoginView.vue"),
    name: "login",
    meta: {
      pageTitle: "Login",
    },
  },
  {
    path: "/otp",
    component: () => import("@/views/auth/OTPView.vue"),
    name: "otp",
    meta: {
      pageTitle: "OTP",
    },
  },
];
