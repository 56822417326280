export enum CallCenterStatusEnum {
  NOT_REACHABLE,
  UNAVAILABLE,
  PACKAGE_SOLD,
  PACKAGE_NOT_SOLD,
}

const getStatusLabel = (unit: CallCenterStatusEnum) => {
  switch (unit) {
    case CallCenterStatusEnum.NOT_REACHABLE:
      return "Ulaşılamadı";
    case CallCenterStatusEnum.UNAVAILABLE:
      return "Müsait Değil";
    case CallCenterStatusEnum.PACKAGE_SOLD:
      return "Paket Satıldı";
    case CallCenterStatusEnum.PACKAGE_NOT_SOLD:
      return "Paket Satılamadı";
    default:
      return "Bilinmiyor";
  }
};

const getStatusForSelect = () => {
  return [
    {
      label: "Ulaşılamadı",
      value: CallCenterStatusEnum.NOT_REACHABLE,
    },
    {
      label: "Müsait Değil",
      value: CallCenterStatusEnum.UNAVAILABLE,
    },
    {
      label: "Paket Satıldı",
      value: CallCenterStatusEnum.PACKAGE_SOLD,
    },
    {
      label: "Paket Satılamadı",
      value: CallCenterStatusEnum.PACKAGE_NOT_SOLD,
    },
  ];
};

export { getStatusLabel, getStatusForSelect };
