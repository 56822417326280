import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "step-1",
  class: "w-100"
}
const _hoisted_2 = {
  key: 0,
  class: "customers-list",
  ref: "customerListDom"
}
const _hoisted_3 = { class: "left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "name-wrapper" }
const _hoisted_6 = { class: "type type-body type-light dark" }
const _hoisted_7 = { class: "type type-caption-semibold gray" }
const _hoisted_8 = { class: "right" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 2,
  class: "py-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_input = _resolveComponent("search-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CardLoader = _resolveComponent("CardLoader")!
  const _component_WarningSection = _resolveComponent("WarningSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_search_input, {
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      onSearch: _ctx.onSearch
    }, null, 8, ["modelValue", "onSearch"]),
    (_ctx.customers.length > 0 && !_ctx.searching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, n) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "item",
              key: n
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: customer.for_table.media?.avatar,
                  alt: "",
                  width: "34",
                  height: "34",
                  class: "rounded-circle"
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(customer.for_table.user), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(customer.for_table.package), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.toggleSelected(n)), ["prevent"]),
                  class: _normalizeClass(["bt", 
              _ctx.selectedCustomers.includes(customer.id)
                ? 'bt-ro-primary-bg'
                : 'bt-light-gray-bordered'
            ])
                }, [
                  _withDirectives(_createVNode(_component_inline_svg, { src: "media/icons/duotune/custom/check.svg" }, null, 512), [
                    [_vShow, _ctx.selectedCustomers.includes(customer.id)]
                  ]),
                  (_ctx.selectedCustomers.includes(customer.id))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Seçildi "))
                    : (_openBlock(), _createElementBlock("span", _hoisted_11, " Seç "))
                ], 10, _hoisted_9)
              ])
            ]))
          }), 128))
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_CardLoader, {
          key: 1,
          height: "70"
        }))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.customers.length < 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_WarningSection, {
            title: "Ops...",
            description: "Herhangi bir sonuç bulunamadı.",
            image: "illustrations/sigma-1/5-dark.png"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}