import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/team",
    redirect: "team/members",
    name: "team",
    meta: {
      module: RoleModuleEnum.TEAM,
    },
    children: [
      /* Roles */
      {
        path: "roles",
        name: "roles",
        component: () => import("@/views/team/roles/RoleIndex.vue"),
        meta: {
          pageTitle: "Roller",
        },
      },
      {
        path: "role-add",
        name: "role-add",
        component: () => import("@/views/team/roles/RoleEdit.vue"),
        meta: {
          pageTitle: "Yeni Rol",
        },
      },
      {
        path: "role/:id",
        name: "role-edit",
        component: () => import("@/views/team/roles/RoleEdit.vue"),
        meta: {
          pageTitle: "Rol",
        },
      },
      /* Roles */

      /* Members */
      {
        path: "members",
        name: "members",
        component: () => import("@/views/team/members/MemberIndex.vue"),
        meta: {
          pageTitle: "Üyeler",
        },
      },
      {
        path: "member-add",
        name: "member-add",
        component: () => import("@/views/team/members/MemberEdit.vue"),
        meta: {
          pageTitle: "Yeni Rol",
        },
      },
      {
        path: "member/:id",
        name: "member-detail",
        component: () => import("@/views/team/members/MemberDetail.vue"),
        meta: {
          pageTitle: "Üye Detayı",
        },
      },
      /* Members */
    ],
  },
];
