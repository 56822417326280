<template>
  <div class="card-px text-center">
    <!--begin::Title-->
    <h2 class="fs-2x fw-bold mb-5">{{ title }}</h2>
    <!--end::Title-->

    <!--begin::Description-->
    <p class="text-gray-400 fs-5 fw-semobold">
      <span>{{ description }}</span>
    </p>
    <!--end::Description-->
  </div>
  <div class="text-center">
    <img
      :src="$prodImage.src('/media/' + image)"
      alt=""
      class="mw-100 mh-300px"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    description: String,
    image: String,
  },
});
</script>
