import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "step-4",
  class: "w-100"
}
const _hoisted_2 = {
  class: "d-flex align-items-center flex-column",
  style: {"gap":"10px"}
}
const _hoisted_3 = {
  class: "dark type type-body type-light d-flex align-items-center",
  style: {"line-height":"34px","gap":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: "/media/illustrations/two-stupid-women.png",
        alt: ""
      }, null, -1)),
      _createElementVNode("h2", _hoisted_3, [
        _createVNode(_component_inline_svg, {
          style: {"color":"#80b918"},
          src: "media/icons/duotune/custom/check.svg"
        }),
        _cache[0] || (_cache[0] = _createTextVNode(" İşlem başarılı bir şekilde tamamlandı! "))
      ])
    ])
  ]))
}