import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/customers",
    redirect: "customers/all-customers",
    name: "customers",
    meta: {
      module: RoleModuleEnum.CUSTOMER,
    },
    children: [
      /* Customers */
      {
        path: "all-customers",
        name: "all-customers",
        component: () =>
          import("@/views/customers/customers/CustomerIndex.vue"),
        meta: {
          pageTitle: "Danışanlar",
          breadcrumbs: ["Danışanlar"],
        },
      },
      {
        path: "customer/:id",
        name: "customer",
        component: () =>
          import("@/views/customers/customers/CustomerDetail.vue"),
        redirect: "overview",
        meta: {
          pageTitle: "Danışan",
        },
        children: [
          {
            path: "overview",
            name: "customer-overview",
            component: () =>
              import(
                "@/views/customers/customers/customer-detail/CustomerOverview.vue"
              ),
            meta: {
              pageTitle: "Genel Bakış",
            },
          },
          {
            path: "assign/:type",
            name: "assign",
            component: () =>
              import(
                "@/views/customers/customers/customer-detail/CustomerAssign.vue"
              ),
            meta: {
              pageTitle: "Atama",
            },
          },
          {
            path: "personal-info",
            name: "personal-info",
            component: () =>
              import(
                "@/views/customers/customers/customer-detail/CustomerPersonalInfo.vue"
              ),
            meta: {
              pageTitle: "Kişisel Bilgileri",
            },
          },
          {
            path: "packages",
            name: "customer-packages",
            component: () =>
              import(
                "@/views/customers/customers/customer-detail/CustomerPackages.vue"
              ),
            meta: {
              pageTitle: "Paketleri",
            },
          },
          // {
          //   path: "report-card",
          //   name: "report-card",
          //   component: () =>
          //     import(
          //       "@/views/customers/customers/customer-detail/CustomerPackages.vue"
          //     ),
          //   meta: {
          //     pageTitle: "Report Card",
          //   },
          // },
          {
            path: "notes",
            name: "notes",
            component: () =>
              import(
                "@/views/customers/customers/customer-detail/NotesList.vue"
              ),
            meta: {
              pageTitle: "Notes",
            },
          },
          {
            path: "measurement",
            name: "measurement",
            component: () =>
              import("@/views/customers/cards/MeasurementTable.vue"),
            meta: {
              pageTitle: "Measurement",
            },
          },
          {
            path: "before-after-overview",
            name: "before-after-overview",
            component: () =>
              import("@/views/customers/before-after/BeforeAfterDetail.vue"),
            meta: {
              pageTitle: "Önceki / Sonraki Fotoğrafları",
            },
          },
          {
            path: "before-after-collages",
            name: "before-after-collages",
            component: () =>
              import("@/views/customers/cards/BeforeAfterPhotosGallery.vue"),
            meta: {
              pageTitle: "Collage",
            },
          },
          {
            path: "evaluations-overview",
            name: "evaluations-overview",
            component: () =>
              import("@/views/customers/evaluations/EvaluationIndex.vue"),
            meta: {
              pageTitle: "Değerlendirmeler",
            },
          },
          {
            path: "meal-photo-overview",
            name: "meal-photo-overview",
            component: () =>
              import("@/views/customers/meal-photos/MealPhotoDetail.vue"),
            meta: {
              pageTitle: "Öğün Fotoğrafları",
            },
          },
          {
            path: "preferences",
            name: "preferences",
            component: () =>
              import(
                "@/views/customers/customers/customer-detail/Preferences.vue"
              ),
            meta: {
              pageTitle: "Preferences",
            },
          },
        ],
      },
      /* Customers */
      {
        path: "before-after-photos",
        component: () =>
          import("@/views/customers/before-after/BeforeAfterIndex.vue"),
        meta: {
          pageTitle: "Öncesi / Sonrası Fotoğrafları",
          breadcrumbs: ["Customers"],
        },
      },
      {
        path: "evaluations",
        component: () =>
          import("@/views/customers/evaluations/EvaluationIndex.vue"),
        meta: {
          pageTitle: "Değerlendirmeler",
        },
      },
      {
        path: "meal-photos",
        component: () =>
          import("@/views/customers/meal-photos/MealPhotoIndex.vue"),
        meta: {
          pageTitle: "Öğün Fotoğrafı",
          breadcrumbs: ["Customers"],
        },
      },
    ],
  },
];
