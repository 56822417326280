<template>
  <el-modal
    title="Paket Tanımla"
    @close="onClose"
    class="message-detail"
    modal-size="lg"
  >
    <template v-slot:body>
      <div
        class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
        id="kt_create_account_stepper"
        ref="verticalWizardRef"
      >
        <div class="row w-100">
          <div class="col-lg-4 mb-lg-0 mb-10">
            <div class="stepper-nav">
              <div
                class="stepper-item"
                v-for="(step, index) in steps"
                :key="index"
                :class="
                  index === currentStep
                    ? 'current'
                    : index < currentStep
                    ? 'completed'
                    : ''
                "
                data-kt-stepper-element="nav"
              >
                <div class="stepper-wrapper">
                  <!--begin::Icon-->
                  <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">{{ index + 1 }}</span>
                  </div>
                  <!--end::Icon-->

                  <!--begin::Label-->
                  <div class="stepper-label">
                    <h3 class="stepper-title">{{ step.title }}</h3>
                    <div class="stepper-desc fw-semobold">
                      {{ step.subtitle }}
                    </div>
                  </div>
                  <!--end::Label-->
                </div>
                <!--begin::Line-->
                <div
                  v-if="index !== steps.length - 1"
                  class="stepper-line h-50px"
                ></div>
                <!--end::Line-->
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div
              :class="
                index === currentStep
                  ? 'current'
                  : index < currentStep
                  ? 'completed'
                  : ''
              "
              data-kt-stepper-element="content"
              v-for="(step, index) in steps"
              :key="index"
            >
              <CardLoader v-if="loading" />
              <component v-else :is="step.component" v-model="data[index]" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button
          v-if="currentStep > 0 && currentStep !== steps.length - 1"
          class="bt bt-light-gray-bordered me-2"
          @click="prevStep"
        >
          Geri
        </button>
        <button class="bt bt-ro-primary-light-bg" @click="nextStep">
          İleri
        </button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ElModal from "@/views/components/Modal.vue";
import CustomerStep from "@/components/modals/contents/DefinePackageSteps/CustomerStep.vue";
import PackageStep from "@/components/modals/contents/DefinePackageSteps/PackageStep.vue";
import FirstSessionStep from "@/components/modals/contents/DefinePackageSteps/FirstSessionStep.vue";
import ClinicStep from "@/components/modals/contents/DefinePackageSteps/ClinicStep.vue";
import CompleteStep from "@/components/modals/contents/DefinePackageSteps/CompleteStep.vue";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";
import router from "@/router";
import { useStore } from "vuex";

export default defineComponent({
  name: "DefinePackage",
  components: {
    ElModal,
    CustomerStep,
    PackageStep,
    FirstSessionStep,
    ClinicStep,
    CompleteStep,
  },
  setup() {
    const currentStep = ref(0);
    const steps = ref([
      {
        component: "CustomerStep",
        title: "Danışan",
        subtitle: "Danışanları seçin",
      },
      {
        component: "PackageStep",
        title: "Paket",
        subtitle: "Atamak istediğiniz paketi seçin",
      },
      {
        component: "FirstSessionStep",
        title: "İlk Randevu",
        subtitle: "İlk randevu tarihini seçin",
      },
      {
        component: "ClinicStep",
        title: "Klinik",
        subtitle: "Klinik seçin",
      },
      {
        component: "CompleteStep",
        title: "Tamamladı",
        subtitle: "Tebrikler!",
      },
    ]);
    const loading = ref<boolean>(false);
    const data = ref([[], null, null, null, null]);

    const store = useStore();

    const nextStep = async () => {
      if (currentStep.value === steps.value.length - 2) {
        loading.value = true;
        const payload = {
          customers: data.value[0],
          package: data.value[1],
          first_session_date: data.value[2],
          clinic_id: data.value[3],
          is_paid: true,
          payment_method: 3,
        };
        await ApiService.U2MClient.post(
          Endpoints.FINANCE_CUSTOMER_PACKAGE_STORE,
          payload
        );
        loading.value = false;
      }
      if (currentStep.value === steps.value.length - 1) {
        router.go(0);
      }
      currentStep.value++;
    };

    const prevStep = () => {
      currentStep.value--;
    };

    const onClose = () => {
      if (currentStep.value === steps.value.length - 1) {
        router.go(0);
      }
      store.commit("closeModal");
    };

    return {
      currentStep,
      steps,
      nextStep,
      prevStep,
      data,
      loading,
      onClose,
    };
  },
});
</script>

<style lang="scss">
.message-detail {
  .modal .card {
    .card-header {
      border-bottom: 1px solid #eff0f2;
    }

    .card-body {
      padding: 30px;
      margin: 0;

      .step {
        width: 100%;
      }
    }
  }
}
</style>
