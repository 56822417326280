const isNumber = (evt) => {
  if (
    evt.keyCode !== 8 &&
    evt.keyCode !== 96 &&
    evt.keyCode !== 48 &&
    !+evt.key
  ) {
    evt.preventDefault();
  }
};
export { isNumber };
