<template>
  <el-modal title="Günlük Seanslar" @close="closeModal" class="daily-meet-modal" modal-size="lg">
    <template v-slot:body>
      <card-loader v-if="loading"></card-loader>
      <div v-else class="row w-100">
        <div class="col-lg-8 mb-lg-0 mb-10">
          <div class="customers-list">
            <div class="item" v-for="(item, n) in meets" :key="n" @click="selectItem(item.id)">
              <div class="left">
                <img :src="item.avatar" class="rounded-circle" width="34" height="34" />
                <div class="name-wrapper">
                  <p class="type type-body type-light" :class="item.id === selected ? 'primary' : 'dark'">
                    {{ item.name }}
                    <small class="fw-bold">({{ sessionLabel(item.session) }})</small>
                  </p>
                  <p class="type type-caption-semibold gray">
                    {{ item.desc }}
                  </p>
                </div>
              </div>
              <div class="right">
                <div class="badge ro-badge" :class="`badge-light-${item.type.color} ${item.type.color}`">
                  {{ item.type.label }}
                </div>
                <p v-if="item.startAt && item.endAt" class="dark type type-caption">
                  {{ item.startAt !== null ? moment(item.startAt).format("HH:mm") : "" }}
                  -
                  {{ item.endAt !== null ? moment(item.endAt).format("HH:mm") : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedItem" class="col-lg-4">
          <div class="right-section">
            <div class="item-header">
              <img :src="selectedItem.avatar" class="rounded-circle" width="34" height="34" alt="" />
              <div class="name-wrapper">
                <p class="type type-body type-light dark">
                  {{ selectedItem.name }}
                </p>
                <p class="type type-caption-semibold gray">
                  {{ selectedItem.desc }}
                </p>
              </div>
            </div>
            <div class="item-body">
              <div class="line">
                <p class="type type-body-medium gray">Seans</p>
                <p class="type type-body-medium dark">
                  {{ sessionLabel(selectedItem.session) }}
                </p>
              </div>
              <div class="line">
                <p class="type type-body-medium gray">Saat</p>
                <p class="type type-body-medium dark">
                  {{ selectedItem.startAt !== null ? moment(selectedItem.startAt).format("HH:mm") : "" }}
                  -
                  {{ selectedItem.endAt !== null ? moment(selectedItem.endAt).format("HH:mm") : "" }}
                </p>
              </div>
              <div class="line" v-if="selectedItem.session === SessionTypeEnum.ONLINE">
                <p class="type type-body-medium gray">Online</p>
                <a
                  @click="
                    () => {
                      $router.push(`/meet-room/${selectedItem?.room_code}`);
                      closeModal();
                    }
                  "
                  class="type type-body-medium primary"
                  >Katıl</a
                >
              </div>
              <div class="line" v-else-if="selectedItem.session === SessionTypeEnum.FACE_TO_FACE">
                <p class="type type-body-medium gray">Klinik</p>
                <p class="type type-body-medium primary">
                  {{ selectedItem.clinic }}
                </p>
              </div>
              <div class="line" v-else-if="selectedItem.session === SessionTypeEnum.DIET_CHANGE">
                <p class="type type-body-medium gray">Değişim</p>
                <a @click="assignDiet" class="type type-body-medium primary">Diyet Ata </a>
              </div>
            </div>
            <ResourceFormDatepicker v-model="editingItem.meetDate" />
            <div class="d-flex gap-3" v-if="selectedItem.session !== SessionTypeEnum.DIET_CHANGE">
              <ResourceFormTimepicker v-model="editingItem.startAt" />
              <ResourceFormTimepicker v-model="editingItem.endAt" />
            </div>
            <div class="item-actions">
              <button class="bt bt-ro-primary-bg" @click="updateMeet()">Kaydet</button>
              <button class="bt bt-ro-danger-bg" @click="deleteMeet(selectedItem['id'])">Sil</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/views/components/Modal.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";
import { getPackageColor, getPackageType } from "@/store/enums/PackageTypeEnum";
import CardLoader from "@/components/loader/CardLoader.vue";
import moment from "moment";
import { getSessionLabel, SessionTypeEnum } from "@/store/enums/SessionTypeEnum";
import { useRouter } from "vue-router";
import ResourceFormDatepicker from "@/components/form/items/ResourceFormDatepicker.vue";
import ResourceFormTimepicker from "@/components/form/items/ResourceFormTimepicker.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "DailyMeetsModal",
  computed: {
    SessionTypeEnum() {
      return SessionTypeEnum;
    },
    moment() {
      return moment;
    },
  },
  components: {
    ResourceFormTimepicker,
    ResourceFormDatepicker,
    CardLoader,
    ElModal,
  },
  setup() {
    const selectedItem = ref();
    const store = useStore();
    const data = ref(store.getters.getModalData);
    const meets = ref();
    const loading = ref(true);
    const selected = ref();
    const router = useRouter();
    const editingItem = ref();
    const closeModal = () => {
      store.commit("closeModal");
    };
    const fetch = async () => {
      loading.value = true;
      const res = await ApiService.U2MClient.get(Endpoints.MEETS_DETAILS, {
        params: {
          date: data.value,
        },
      });

      meets.value = res.data.data.map((item) => ({
        id: item.id,
        avatar: item.media.avatar,
        name: item.full_name,
        desc: item.package_name,
        type: {
          label: getPackageType(item.package_category),
          color: getPackageColor(item.package_category),
        },
        startAt: item.start_at,
        endAt: item.end_at,
        meetDate: item.meet_date,
        session: item.type_id,
        clinic: item.clinic_id,
        room_code: item.room_code,
        userId: item.user_id,
      }));
      if (meets.value.length > 0) selectItem(meets.value[0].id);
      loading.value = false;
      console.log("meets :", meets.value);
    };
    onMounted(async () => {
      await fetch();
    });
    const sessionLabel = computed(() => (session) => getSessionLabel(session));
    const selectItem = (id) => {
      selected.value = id;
      selectedItem.value = JSON.parse(JSON.stringify(meets.value.find((item) => item.id === selected.value)));
      editingItem.value = JSON.parse(JSON.stringify(meets.value.find((item) => item.id === selected.value)));
    };
    const assignDiet = () => {
      closeModal();
      router.push(`/customers/customer/${selectedItem.value.userId}/assign/diet`);
    };
    const deleteMeet = async (id) => {
      loading.value = true;
      await ApiService.U2MClient.delete(Endpoints.MEETS_DELETE.replaceAll("{id}", id));
      await fetch();
    };
    const updateMeet = async () => {
      loading.value = true;
      const payload = {
        meet_date: editingItem.value.meetDate,
        start_at: editingItem.value.startAt,
        end_at: editingItem.value.endAt,
      };
      await ApiService.U2MClient.put(Endpoints.MEETS_DELETE.replaceAll("{id}", editingItem.value.id), payload);
      loading.value = false;
      store.commit("closeModal");
      Swal.fire({
        icon: "success",
        title: "Takvim",
        text: "Toplantı güncellendi.",
        confirmButtonColor: "#80B918",
        confirmButtonText: "Tamam",
      });
      router.go(0);
    };
    return {
      closeModal,
      data,
      meets,
      loading,
      selectedItem,
      selectItem,
      selected,
      sessionLabel,
      assignDiet,
      deleteMeet,
      updateMeet,
      editingItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.daily-meet-modal {
  .modal .card {
    .card-header {
      border-bottom: 1px solid #eff0f2;
      padding: 30px;
    }

    .card-body {
      padding: 30px;

      .right-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .item-header {
          display: flex;
          align-items: center;
          border: 1px solid rgba(142, 154, 174, 0.08);
          border-radius: 12px;
          padding: 15px;
          gap: 15px;
          width: 100%;

          .name-wrapper {
            flex: 1;
          }
        }

        .item-body {
          background: #f3f5f8;
          border-radius: 12px;
          padding: 5px 15px;
          display: flex;
          flex-direction: column;

          .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(142, 154, 174, 0.08);
            }
          }
        }

        .item-actions {
          display: flex;
          gap: 10px;

          button {
            flex: 1;
          }
        }
      }
    }
  }
}

.customers-list {
  border: 1px solid rgba(142, 154, 174, 0.08);
  border-radius: 12px;
  max-height: 332px;
  overflow-y: scroll;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #8e9aae14;
    }

    .left {
      display: flex;

      .name-wrapper {
        margin-left: 15px;

        p:first-of-type {
          margin-bottom: 4px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;

      .bt {
        padding: 8px 14px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    @media screen and (max-width: 554px) {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }
}
</style>
