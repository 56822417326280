import { Modals } from "@/store/enums/StoreEnums";
import { ModalEnum } from "@/store/enums/ModalEnum";
import { ModalPayloadInterface } from "@/store/interfaces/core/ModalPayloadInterface";

const modals = {
  state: () => ({
    addFood: false,
    addAlternativeFood: false,
    evaluationDetail: false,
    mealPhoto: false,
    photoPreview: false,
    deleteConfirm: false,

    newMeet: false,
    newMeet2: false,
    addPayment: false,
    addNote: false,
    messageDetail: false,
    addPhoto: false,
    invoiceDetail: false,
    definePackage: false,
    activeModal: null,
    callback: null,
    fallback: null,
    data: null,
  }),
  mutations: {
    showModal(state, modal) {
      state[modal] = true;
    },
    hiddenModal(state, modal) {
      state[modal] = false;
    },
    closeModal(state) {
      state.activeModal = null;
      state.callback = null;
      state.fallback = null;
      state.data = null;
    },
    setModal(state, payload: ModalPayloadInterface) {
      state.activeModal = payload.modal;
      state.callback = payload.callback;
      state.fallback = payload.fallback;
      state.data = payload.data;
    },
    modalCallback(state, payload) {
      if (typeof state.callback === "function") {
        state.callback(payload);
      }
    },
    modalFallback(state, payload) {
      if (typeof state.fallback === "function") {
        state.fallback(payload);
      }
    },
  },
  getters: {
    [Modals.ADD_FOOD_MODAL](state) {
      return state.addFood;
    },
    [Modals.EVALUATION_DETAIL_MODAL](state) {
      return state.evaluationDetail;
    },
    [Modals.MEAL_PHOTO_MODAL](state) {
      return state.mealPhoto;
    },
    [Modals.PHOTO_PREVIEW_MODAL](state) {
      return state.photoPreview;
    },
    [Modals.DELETE_CONFIRM_MODAL](state) {
      return state.deleteConfirm;
    },
    [Modals.NEW_MEET_MODAL](state) {
      return state.newMeet;
    },
    [Modals.NEW_MEET_MODAL_2](state) {
      return state.newMeet2;
    },
    [Modals.ADD_PAYMENT_MODAL](state) {
      return state.addPayment;
    },
    [Modals.ADD_NOTE_MODAL](state) {
      return state.addNote;
    },
    [Modals.MESSAGE_DETAIL_MODAL](state) {
      return state.messageDetail;
    },
    [Modals.ADD_PHOTO_MODAL](state) {
      return state.addPhoto;
    },
    [Modals.INVOICE_DETAIL_MODAL](state) {
      return state.invoiceDetail;
    },
    [Modals.DEFINE_PACKAGE_MODAL](state) {
      return state.definePackage;
    },
    [Modals.ADD_ALTERNATIVE_FOOD](state) {
      return state.addAlternativeFood;
    },
    getActiveModal(state) {
      return state.activeModal;
    },
    getModalData(state) {
      return state.data;
    },
  },
};

export default modals;
