<template>
  <component :is="$store.getters.getActiveModal" />
  {{ $store.getters.getActiveModal }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AddFoodModal from "@/components/modals/contents/AddFoodModal.vue";
import CompareItemsModal from "@/components/modals/contents/CompareItemsModal.vue";
import AddCallCenterNoteModal from "@/components/modals/contents/AddCallCenterNoteModal.vue";
import DefinePackageModal from "@/components/modals/contents/DefinePackageModal.vue";
import AddPaymentModal from "@/components/modals/contents/AddPaymentModal.vue";
import ContactModal from "./contents/ContactModal.vue";
import AddRecipeModal from "@/components/modals/contents/AddRecipeModal.vue";
import PhotoPreviewModal from "@/components/modals/forms/PhotoPreviewModal.vue";
import DailyMeetsModal from "@/components/modals/contents/DailyMeetsModal.vue";
import NewMeetModal from "@/views/calendar/components/NewMeetModal/NewMeetModal.vue";
import NewMeetModal2 from "@/views/calendar/components/NewMeetModal/NewMeetModal2.vue";

export default defineComponent({
  name: "ModalContainer",
  components: {
    AddFoodModal,
    AddRecipeModal,
    CompareItemsModal,
    AddCallCenterNoteModal,
    DefinePackageModal,
    AddPaymentModal,
    ContactModal,
    PhotoPreviewModal,
    DailyMeetsModal,
    NewMeetModal,
    NewMeetModal2,
  },
});
</script>
