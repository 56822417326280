import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import UserModel from "@/store/models/UserModel";
import {
  UserStoreActions,
  UserStoreMutations,
} from "@/store/enums/UserStoreEnum";
import JwtService from "@/core/services/JwtService";
import { TokenTypeEnum } from "@/core/enums/TokenTypeEnum";
import TokenModel from "@/store/models/TokenModel";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";

@Module
class UserModule extends VuexModule {
  user: UserModel | null = null;

  get getUser(): UserModel | null {
    return this.user;
  }

  @Mutation
  [UserStoreMutations.SET_USER_TO_STATE](userObject) {
    console.log("useer2", userObject);
    this.user = new UserModel(
      userObject.first_name,
      userObject.last_name,
      userObject.email,
      userObject.phone,
      userObject.registration_completed,
      userObject.role.modules,
      userObject.media.avatar
    );
  }

  @Mutation
  [UserStoreMutations.DELETE_USER_STATE]() {
    this.user = null;
  }

  @Action
  async [UserStoreActions.SET_U2M_TO_STORAGE](token: TokenModel) {
    JwtService.saveToken(TokenTypeEnum.U2M, token);
  }

  @Action
  async [UserStoreActions.SHOW]() {
    const result = await ApiService.U2MClient.get(Endpoints.AUTH_USER_SELF);
    console.log(result.data.data);
    this.context.commit(UserStoreMutations.SET_USER_TO_STATE, result.data.data);
  }
}

export default UserModule;
