import { RoleModuleEnum } from "@/store/enums/RoleModuleEnum";

export default [
  {
    path: "/call-center",
    redirect: "call-center/all-customers",
    name: "call-center",
    meta: {
      module: RoleModuleEnum.CALL_CENTER,
    },
    children: [
      {
        path: "all-customers",
        name: "call-center-customers",
        component: () =>
          import("@/views/call-center/customers/CustomerIndex.vue"),
        meta: {
          pageTitle: "Tüm Danışanlar",
        },
      },
      {
        path: "all-customers/:id",
        name: "call-center-notes",
        component: () =>
          import("@/views/call-center/customers/CustomerDetail.vue"),
        meta: {
          pageTitle: "Çağrı Merkezi Notları",
        },
      },
      {
        path: "call-requests",
        name: "call-requests",
        component: () =>
          import("@/views/call-center/call-requests/CallRequestIndex.vue"),
        meta: {
          pageTitle: "Çağrı Talepleri",
        },
      },
    ],
  },
];
