import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "food-list",
  class: "food-list",
  ref: "foodListDom"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "type type-body type-light" }
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = {
  key: 0,
  class: "quantity-input"
}
const _hoisted_7 = ["onInput", "value"]
const _hoisted_8 = { class: "quantity-type" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 2,
  class: "py-5"
}
const _hoisted_11 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_input = _resolveComponent("search-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CardLoader = _resolveComponent("CardLoader")!
  const _component_WarningSection = _resolveComponent("WarningSection")!
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    title: "Tarif Ekle",
    class: "add-food-modal",
    "modal-size": "lg"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_search_input, {
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        onSearch: _ctx.onSearch
      }, null, 8, ["modelValue", "onSearch"]),
      (_ctx.foods.length > 0 && !_ctx.searching)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.foods, (item, n) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: n
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    src: item.photo,
                    width: "44",
                    height: "44",
                    alt: ""
                  }, null, 8, _hoisted_3),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(item.name), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.isSelected(item))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("input", {
                          type: "text",
                          placeholder: "Miktar",
                          onInput: ($event: any) => (_ctx.onUpdateQuantity(item, $event.target.value)),
                          value: _ctx.getQuantity(item)
                        }, null, 40, _hoisted_7),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getUnitShortText(item.unit)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: _normalizeClass(["bt", _ctx.isSelected(item) ? 'bt-ro-primary-bg' : 'bt-ro-gray-bg']),
                    onClick: ($event: any) => (_ctx.onToggleSelect(item))
                  }, [
                    (_ctx.isSelected(item))
                      ? (_openBlock(), _createBlock(_component_inline_svg, {
                          key: 0,
                          src: _ctx.$prodImage.src('/media/icons/duotune/custom/check.svg')
                        }, null, 8, ["src"]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.isSelected(item) ? "Seçildi" : "Seç"), 1)
                  ], 10, _hoisted_9)
                ])
              ]))
            }), 128))
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_CardLoader, {
            key: 1,
            height: "70"
          }))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.foods.length < 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_WarningSection, {
              title: "Ops...",
              description: "Herhangi bir sonuç bulunamadı.",
              image: "illustrations/sigma-1/5-dark.png"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSave && _ctx.onSave(...args))),
          class: "bt bt-ro-primary-light-bg"
        }, " Kaydet ")
      ])
    ]),
    _: 1
  }))
}