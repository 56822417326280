import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// Routes
import AuthRoutes from "@/router/routes/auth";
import CalendarRoutes from "@/router/routes/calendar";
import CallCenterRoutes from "@/router/routes/call-center";
import CustomersRoutes from "@/router/routes/customers";
import DashboardRoutes from "@/router/routes/dashboard";
import DietsRoutes from "@/router/routes/diets";
import FinanceRoutes from "@/router/routes/finance";
import MessagesRoutes from "@/router/routes/messages";
import PackagesRoutes from "@/router/routes/packages";
import SettingsRoutes from "@/router/routes/settings";
import SystemRoutes from "@/router/routes/system";
import TeamRoutes from "@/router/routes/team";
import NotificationRoutes from "@/router/routes/notifications";
import AuthMiddleware from "@/router/middleware/AuthMiddleware";
import { AuthGuards } from "@/router/middleware/enums/AuthGuards";
import store from "@/store";
import { gerRouteNameOfModules } from "@/store/enums/RoleModuleEnum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    meta: {
      guard: AuthGuards.AUTH,
    },
    children: [
      {
        path: "/",
        component: () => import("@/layouts/main-layout/MainLayout.vue"),
        redirect: "home",
        children: [
          {
            path: "contact-forms",
            name: "contact-form",
            component: () => import("@/views/home/tabs/ContactForms.vue"),
            meta: {
              pageTitle: "Contact Forms",
            },
          },
          //@ts-ignore
          ...CalendarRoutes,
          //@ts-ignore
          ...CallCenterRoutes,
          //@ts-ignore
          ...CustomersRoutes,
          //@ts-ignore
          ...DashboardRoutes,
          //@ts-ignore
          ...DietsRoutes,
          //@ts-ignore
          ...FinanceRoutes,
          //@ts-ignore
          ...MessagesRoutes,
          //@ts-ignore
          ...PackagesRoutes,
          //@ts-ignore
          ...SettingsRoutes,
          //@ts-ignore
          ...TeamRoutes,
          //@ts-ignore
          ...NotificationRoutes,
        ],
      },
    ],
  },
  {
    path: "/meet-room/:id",
    name: "meet-room",
    component: () => import("@/views/jitsimeet/jitsiComp.vue"),
  },

  {
    path: "/auth",
    name: "auth",
    component: () => import("@/layouts/LoginLayout.vue"),
    redirect: "login",
    meta: {
      guard: AuthGuards.GUEST,
    },
    children: [...AuthRoutes],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [...SystemRoutes],
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/customers/all-customers",
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const pageTitle =
    to.meta.pageTitle !== undefined ? `${to.meta.pageTitle} - ` : "";
  document.title = `${pageTitle} ${process.env.VUE_APP_NAME}`;
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  if (to.meta.guard !== null) {
    const middleware = new AuthMiddleware(to.meta.guard as AuthGuards);

    await middleware.protect();
  }
  if (to.meta.module !== undefined) {
    console.log(to.meta.module);
    console.log("user", store.getters.getUser);
    if (!store.getters.getUser.can.includes(to.meta.module)) {
      router.push({
        name: gerRouteNameOfModules(store.getters.getUser.can[0]),
      });
    }
  }
});

export default router;
