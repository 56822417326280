<template>
  <el-modal
    title="Yeni Tarih Atama"
    @close="$store.commit('closeModal')"
    class="new-meet-modal-2"
    modal-size="lg"
  >
    <template v-slot:body>
      <div
        class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
        id="kt_create_account_stepper"
        ref="verticalWizardRef"
      >
        <div class="row w-100">
          <div class="col-lg-4 mb-lg-0 mb-10">
            <div class="stepper-nav">
              <div
                class="stepper-item"
                :class="
                  index === currentStep
                    ? 'current'
                    : index < currentStep
                    ? 'completed'
                    : ''
                "
                data-kt-stepper-element="nav"
                v-for="(step, index) in steps"
                :key="index"
              >
                <div class="stepper-wrapper">
                  <!--begin::Icon-->
                  <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">{{ index + 1 }}</span>
                  </div>
                  <!--end::Icon-->

                  <!--begin::Label-->
                  <div class="stepper-label">
                    <h3 class="stepper-title">{{ step.title }}</h3>
                    <div class="stepper-desc fw-semobold">
                      {{ step.subtitle }}
                    </div>
                  </div>
                  <!--end::Label-->
                </div>
                <!--begin::Line-->
                <div
                  v-if="index !== steps.length - 1"
                  class="stepper-line h-50px"
                ></div>
                <!--end::Line-->
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <form
              novalidate="novalidate"
              id="kt_create_account_form"
              @submit="handleStep"
            >
              <div
                :class="
                  index === currentStep
                    ? 'current'
                    : index < currentStep
                    ? 'completed'
                    : ''
                "
                data-kt-stepper-element="content"
                v-for="(step, index) in steps"
                :key="index"
              >
                <CardLoader v-if="loading" />
                <component v-else :is="step.component" v-model="data[index]" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-footer">
        <button
          v-if="currentStep > 0"
          class="bt bt-light-gray-bordered me-2"
          @click="prevStep"
        >
          Geri
        </button>
        <button class="bt bt-ro-primary-light-bg" @click="nextStep">
          İleri
        </button>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import ElModal from "@/views/components/Modal.vue";
import Step1 from "@/views/calendar/components/NewMeetModal/Step1.vue";
import Step2 from "@/views/calendar/components/NewMeetModal/Step2.vue";
import Step3 from "@/views/calendar/components/NewMeetModal/Step3_3.vue";
import Step4 from "@/views/calendar/components/NewMeetModal/Step4.vue";
import { EventTypeEnum } from "@/store/enums/EventTypeEnum";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";

export default defineComponent({
  name: "NewMeetModal2",
  components: { Step1, Step2, Step3, Step4, ElModal },
  setup() {
    const currentStep = ref<number>(0);
    const steps = ref([
      {
        component: "step-3",
        title: "Tarih & Saat",
        subtitle: "Seans Tarihini Seç",
      },
      {
        component: "step-2",
        title: "Tip",
        subtitle: "Seans Tipi Belirle",
      },
      {
        component: "step-1",
        title: "Danışan",
        subtitle: "Danışanları Seç",
      },
      {
        component: "step-4",
        title: "Tamamlandı",
        subtitle: "Seanslar Atandı!",
      },
    ]);

    const loading = ref<boolean>(false);
    const data = ref([null, EventTypeEnum.ONLINE, []]);
    const router = useRouter();

    const store = useStore();

    function handleStep(e) {
      e.preventDefault();
    }

    const nextStep = async () => {
      if (currentStep.value === steps.value.length - 2) {
        loading.value = true;
        const payload = {
          customers: data.value[2],
          meet_type: data.value[1],
          //@ts-ignore
          meet_date: data.value[0]?.meet_date,
          //@ts-ignore
          new_date: data.value[0]?.new_date,
        };
        await ApiService.U2MClient.put(Endpoints.MEETS_UPDATE, payload);
        loading.value = false;
      }
      if (currentStep.value === steps.value.length - 1) {
        router.go(0);
      }
      currentStep.value++;
    };

    function prevStep() {
      currentStep.value--;
    }

    const onClose = () => {
      store.commit("closeModal");
    };

    return {
      handleStep,
      currentStep,
      steps,
      nextStep,
      prevStep,
      onClose,
      data,
      loading,
    };
  },
});
</script>

<style lang="scss">
.new-meet-modal-2 {
  .modal {
    overflow: unset;
  }
}
</style>
