<template>
  <div id="step-2" class="step">
    <p
      class="type type-body-medium dark"
      style="padding: 12px 0; margin-bottom: 10px"
    >
      İlk Randevu Tarihini Seç
    </p>
    <ResourceFormDatepicker v-model="date" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import ResourceFormSelect from "@/components/form/items/ResourceFormSelect.vue";
import ApiService from "@/core/services/ApiService";
import { Endpoints } from "@/store/enums/network/Endpoints";
import ResourceFormDatepicker from "@/components/form/items/ResourceFormDatepicker.vue";

export default defineComponent({
  name: "FirstSessionStep",
  components: { ResourceFormDatepicker },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const date = ref(null);

    onMounted(() => {
      watch(date, (val: string | null) => {
        emit("update:modelValue", val?.split("T")[0]);
      });
    });

    return {
      date,
    };
  },
});
</script>

<style lang="scss"></style>
